import { Button, Checkbox, Grid, Heading, HStack, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { downloadFileFromUrl } from 'helpers/downloadFiles';
import { get, isNil, without } from 'lodash';
import * as React from 'react';
import { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowDownward } from 'react-icons/md';
import * as yup from 'yup';

import { ADDON_KEYS, Document, SubscriptionAddon } from '../../../api/types';
import { AlexHandyMail } from '../../../components/illustrations';
import AlertModal from '../../../components/modals/AlertModal';
import FormEmbeddedAlertModal from '../../../components/modals/FormEmbeddedAlertModal';
import { DataTable } from '../../../components/Table/DataTable';
import { eventTrack } from '../../../helpers/analytics';
import dateTransformer from '../../../helpers/dateTransformer';
import { simpleInput } from '../../../helpers/makeFormFields';
import { Subscription } from '../../../hooks/queries/workshop/useWorkshopQuery';
import FileForm from '../../serviceRecord/FileForm';

const DocumentsSection = (props: {
  isLoadingParent: boolean;
  subscription: Subscription | object;
  documents: Array<Document> | undefined;
  sendRequest(data: FormData): void;
  sendFiles(data: any): void;
  remove(serviceId: number, documentId: number): void;
  anchorId: string;
  parentId: number;
  timezone: string;
  addons: SubscriptionAddon[];
  costControlDisabled: boolean;
  url: string;
  callback?: any;
}) => {
  const {
    documents = [],
    sendRequest,
    sendFiles,
    remove,
    addons,
    timezone,
    anchorId,
    parentId,
    costControlDisabled,
    subscription = {},
    url,
    callback,
  } = props;
  const { t } = useTranslation();
  const axios = useAxios();
  const [selectedRows, setSelectedRows] = useState<SetStateAction<any>>([]);

  const schema = yup
    .object({
      email: yup
        .string()
        .email()
        .matches(/^[^äÄöÖüÜ]*$/, { message: t('forms:email.special_characters') })
        .label(t('forms:email.label'))
        .required(),
    })
    .required();

  const columns = React.useMemo(
    () => [
      {
        id: 'select',
        accessor: 'id',
        Cell: ({ value }: { value: any }) => {
          return (
            <Checkbox
              isChecked={selectedRows.includes(value)}
              value={value}
              onChange={() => {
                if (selectedRows.includes(value)) {
                  setSelectedRows(without(selectedRows, value));
                } else {
                  setSelectedRows(selectedRows.concat([value]));
                }
              }}
            />
          );
        },
      },
      {
        Header: t('documents:uploaded_at.label'),
        accessor: (row: { created_at: string }) => dateTransformer(row.created_at, timezone),
      },
      {
        Header: t('documents:type.label'),
        accessor: (row: { type: string }) => t(`documents:type.${row.type}`),
      },
      {
        Header: t('documents:form.comment.label'),
        accessor: 'comment',
      },
      {
        id: 'download',
        accessor: 'file',

        Cell: (cellProps: { row: { original: any } }) => {
          const { row } = cellProps;
          return (
            <Button
              leftIcon={<MdArrowDownward />}
              variant="primary"
              size="sm"
              my="2"
              onClick={() =>
                downloadFileFromUrl(
                  axios,
                  `${config.apiBaseUrl}/workshop/services/${parentId}/documents/${row.original.id}/download`,
                  'Service Document.pdf',
                )
              }
            >
              {t('common:download')}
            </Button>
          );
        },
      },
      {
        id: 'button',
        accessor: 'id',

        Cell: (cellProps: { row: { original: Document; index: number } }) => {
          const { row } = cellProps;
          const documentId = row?.original?.id;
          const shouldBeDisabled = isNil(row.original.workshop_user_id);
          const button = (
            <Button
              variant="outline"
              size={'sm'}
              color={'accent'}
              onClick={() =>
                NiceModal.show(AlertModal, {
                  onSubmit: () => remove(parentId, documentId),
                  children: t('documents:delete.modal.description'),
                  content: {
                    header: t('documents:delete.modal.header'),
                    footer: {
                      buttons: {
                        cancelCaption: t('common:cancel'),
                        actionCaption: t('common:delete'),
                      },
                    },
                  },
                })
              }
              disabled={shouldBeDisabled}
              data-test-id={'delete-' + row.index}
            >
              {t('common:delete')}
            </Button>
          );

          return shouldBeDisabled ? (
            <Tooltip label={t('documents:delete.disabled')}>
              <span>{button}</span>
            </Tooltip>
          ) : (
            button
          );
        },
      },
    ],
    [documents, selectedRows],
  );
  const activeAddons = get(subscription, 'active_addons', {});
  const hasDocksPackage = get(activeAddons, ADDON_KEYS.OE_DOCS, false);
  const showOeDocsElementsOnly = true;
  const sendDocumentsButton = (
    <Button
      mr={2}
      variant="outline"
      data-test-id="complete-button"
      disabled={selectedRows.length === 0 || !hasDocksPackage}
      onClick={() =>
        NiceModal.show(FormEmbeddedAlertModal, {
          showOeDocsElementsOnly,
          onFormSuccess: () => eventTrack('sent_vehicle_documents'),
          onMutate: (data: any) => sendFiles({ ...data, documents: selectedRows }),
          content: {
            header: t('components:service_process_card.sent_documents.modal.header'),
            formTestId: 'send-files-form',
            formFields: (props: { register: any; setValue: any; errors: any }) => {
              const { register, setValue, errors } = props;
              return (
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', sm: '1fr auto' }} gap={3} paddingBlock={4}>
                  <Grid gap={{ base: 4, lg: 8 }} alignSelf={'center'}>
                    <Text>{t('components:service_process_card.sent_documents.modal.note')}</Text>

                    {simpleInput({
                      name: 'email',
                      label: t('forms:email.label'),
                      placeholder: t('forms:email.placeholder'),
                      customHook: (val) => setValue('email', val.replace(/ /g, '')),
                      register,
                      errors,
                      schema,
                    })}
                  </Grid>
                  <Icon as={AlexHandyMail} boxSize={{ base: '40', lg: '56' }} placeSelf={'end'} />
                </Grid>
              );
            },
            footer: {
              buttons: {
                formButtonTestId: 'send-files-button',
                actionCaption: t('common:send'),
              },
            },
          },
          schema,
        })
      }
    >
      {t('components:service_process_card.sent_documents.action')}
    </Button>
  );
  return (
    <Stack minH="fit-content" id={anchorId} data-test-id="documents-card" className={'tour-process-card-documents'} pt={4}>
      <Heading size="xxs">{t('documents:card.header')}</Heading>
      <Stack direction={['column', 'row']} w={'100%'} className={anchorId}>
        <Stack direction="column" w={'100%'} spacing={{ base: 4, lg: 6 }}>
          <Stack direction="column" w={'100%'}>
            {documents.length === 0 ? (
              <Text data-test-id="no-documents">{t('documents:card.no_documents')}</Text>
            ) : (
              <DataTable data-test-id="documents-table" data={documents} columns={columns} isMoreThenOnePage={false} hasFilters={false} />
            )}
          </Stack>
          <HStack>
            <FileForm
              subscription={subscription}
              addons={addons}
              parentId={parentId}
              url={url}
              hasOneDocument={documents.length > 0}
              costControlDisabled={costControlDisabled}
              sendRequest={sendRequest}
              callback={callback}
            />
            {selectedRows.length === 0 ? (
              <Tooltip
                label={
                  hasDocksPackage
                    ? t('components:service_process_card.sent_documents.tooltip')
                    : t('components:service_process_card.sent_documents.purchase_package')
                }
              >
                <span>{sendDocumentsButton}</span>
              </Tooltip>
            ) : (
              sendDocumentsButton
            )}
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DocumentsSection;
