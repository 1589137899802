import { useToast } from '@chakra-ui/react';
import { Results } from 'api/types';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { FormSchema } from '../../features/serviceRecord/MakeSpecificDataStep';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';
async function getSchema(
  axios: AxiosInstance,
  versionId: number,
): Promise<Results<FormSchema>> {
  const { data } = await axios.get(
    `${config.apiBaseUrl}/workshop/dsb-form-versions/${versionId}/schema`,
  );
  return data;
}

export default function useFormSchemaQuery(versionId: number) {
  const axios = useAxios();
  const toast = useToast();
  const { t } = useTranslation();
  return useQuery(getKey(versionId), () => getSchema(axios, versionId), {
    staleTime: DURATION.Minute * 5,
    enabled: !!versionId,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      manageErrorResponse({ toastInstance: toast, error: JSON.stringify(error), t });
    },
  });
}

function getKey(versionId: number): QueryKey {
  return ['FormSchema', versionId];
}

useFormSchemaQuery.getKey = getKey;
