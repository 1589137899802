import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { CostOverview, Results } from '../../api/types';
import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

async function getCostOverview(axios: AxiosInstance): Promise<Results<CostOverview>> {
  const { data } = await axios.get(
    `${config.apiBaseUrl}/workshop/subscription/unbilled-charges`,
  );
  return data;
}

export default function useCostOverviewQuery() {
  const axios = useAxios();
  const toast = useToast();
  const { t } = useTranslation();
  return useQuery(getKey(), () => getCostOverview(axios), {
    staleTime: DURATION.Minute * 5,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: true,
    refetchOnMount: 'always',
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
function getKey(): QueryKey {
  return ['CostOverview'];
}

useCostOverviewQuery.getKey = getKey;
