import { captureMessage } from '@sentry/react';
import { Environments } from 'helpers/environments';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { isEmpty } from 'lodash';
import { initReactI18next } from 'react-i18next';

import config from './config';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: '/lang/{{lng}}/{{ns}}.json',
    },
    fallbackLng: 'en',
    fallbackNS: 'common',
    defaultNS: 'pages',
    ns: [
      'abtests',
      'accounts',
      'activity',
      'addons',
      'announcements_dynamic',
      'chargebee',
      'common',
      'components',
      'controlling',
      'documents',
      'errors',
      'forms',
      'location',
      'pages',
      'plans',
      'promotions',
      'service_history_request',
      'service_record',
      'sidebar',
      'status',
      'tours',
      'workshop',
      'workshop_update_request',
      'workshop_verification_request',
    ],
    returnEmptyString: false,
    debug: config.appEnv !== 'production' && config.appEnv === 'local',
    saveMissing: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    //for more options available https://www.npmjs.com/package/i18next-browser-languagedetector?activeTab=readme
    detection: {
      htmlTag: document.documentElement,
      order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    missingKeyHandler: (lngs, ns, key) => {
      !isEmpty(lngs) &&
        !lngs.includes('cimode') &&
        captureMessage(`Missing translation key: ${lngs}:${ns}:${key}`);
    },
  });

if (config.appEnv === Environments.LOCAL && import.meta.hot) {
  import.meta.hot.on('locales-update', () => {
    i18n.reloadResources().then(() => {
      i18n.changeLanguage(i18n.language);
    });
  });
}

//reset the language in the html tag
i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});
export default i18n;
