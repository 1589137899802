import { Box, Divider, Grid, Stack, Text } from '@chakra-ui/react';
import { FONT_WEIGHTS, FONTS } from 'global/Fonts';
import { formatMultiCurrencyIntl } from 'helpers/formatCurrency';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { BILLING_CYCLE, SubscriptionPlan } from '../../../../api/types';
import { ChoiceSwitch } from '../../../../components/ChoiceSwitch';
import { useUserContext } from '../../../../context/UserContextProvider';
import { Plan } from '../plan/Plan';
import { PlanCard, PlanCardGroup } from '../plan/PlanCardGroup';
import { PlanCardGroupCreate } from '../plan/PlanCardGroupCreate';

export function AvailablePlansCard(props: {
  abTestNewDesign?: boolean;
  currency: string | undefined;
  plans: SubscriptionPlan[];
  billingCycle: BILLING_CYCLE;
  onChangeInterval: (ev: any) => void;
  selectedPlan?: SubscriptionPlan;
  onChangeActivePlan: (selectedPlanId: string) => void;
  isLoading: boolean;
  hideYearlyServiceFee?: boolean;
  disableBillingCycle?: boolean;
  isCreatePage?: boolean;
  workshopLegacy?: boolean;
  temporaryPromotionGuard: boolean;
  savings: number;
  recommendedPlanId: number;
}) {
  const {
    abTestNewDesign,
    currency,
    billingCycle,
    plans,
    onChangeActivePlan,
    onChangeInterval,
    recommendedPlanId,
    savings,
    selectedPlan,
    hideYearlyServiceFee = false,
    disableBillingCycle = false,
  } = props;

  const { t } = useTranslation();
  const userContext = useUserContext();

  const itArticleMonth = userContext.workshop?.country === 'IT' ? 'common:article_month' : '';
  const itArticleYear = userContext.workshop?.country === 'IT' ? 'common:atricle_year' : '';

  const isYearlyCycle = billingCycle === BILLING_CYCLE.YEARLY;
  const filteredPlansCycles = isYearlyCycle ? plans.filter((plan) => plan.slug !== 'pay_per_use') : plans;
  return (
    <>
      <Stack direction={'column'}>
        <Grid gap={{ base: 4, lg: 6 }} placeContent={'center'} textAlign={'center'} paddingBlock={{ base: 4, lg: 4 }}>
          {!disableBillingCycle && (
            <Stack alignItems={'center'}>
              <ChoiceSwitch
                choice1={{
                  label: t('pages:subscription.frequency.monthly'),
                  value: BILLING_CYCLE.MONTHLY,
                }}
                choice2={{
                  label: t('pages:subscription.frequency.annual'),
                  value: BILLING_CYCLE.YEARLY,
                }}
                defaultValue={billingCycle}
                disabled={disableBillingCycle || Boolean(selectedPlan?.is_pay_per_use)}
                //@ts-ignore
                onChange={(event) => onChangeInterval(event.target.value)}
              />
            </Stack>
          )}
          <Text mb={10} mt={-3}>
            {t('pages:subscription.savings.annual_billing')}
          </Text>
        </Grid>
        <PlanCardGroupCreate
          onChange={onChangeActivePlan}
          value={selectedPlan?.slug}
          defaultValue={selectedPlan?.slug}
          templateColumns={{
            lg: `repeat(${isYearlyCycle ? 3 : 4}, minmax(11rem, 15rem))`,
            md: 'repeat(2, minmax(17rem, 18rem))',
          }}
          justifyContent="center"
          gridGap={{ base: 20, lg: 5 }}
        >
          {filteredPlansCycles.map((plan) => (
            <PlanCard key={plan.slug} value={plan.slug}>
              <Plan
                abTestNewDesign={abTestNewDesign}
                onChange={onChangeActivePlan}
                key={plan.slug}
                plan={plan}
                recommendedPlanId={recommendedPlanId}
                isRecommended={plan.id === recommendedPlanId}
                isSelected={selectedPlan?.slug === plan?.slug}
                billingCycle={billingCycle}
                hideSetupFee={hideYearlyServiceFee}
                hideSelectButton={true}
                isCreate={true}
              />
            </PlanCard>
          ))}
        </PlanCardGroupCreate>
      </Stack>
    </>
  );
}
