import { BoxProps, Button, HStack, Icon, Text } from '@chakra-ui/react';
import Link from 'layout/Link';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Promotion } from '../api/types';
import { Discount } from './Icons';
import { InfoCard } from './InfoCard';

interface IPromotionBannerProps extends BoxProps {
  url?: string;
  promotion: Promotion;
  showCta?: boolean;
  buttonText?: string;
  bgColor?: string;
  color?: string;
}

export const PromotionBanner = (props: IPromotionBannerProps) => {
  const { t } = useTranslation();
  const { url = false, promotion, buttonText = t('sidebar:account.subscription'), bgColor = 'orange.500', color = 'white', showCta = true } = props;

  return (
    <InfoCard bgColor={bgColor}>
      <HStack minWidth={300}>
        <Icon as={Discount} color={color} boxSize="10" mr={30} />
        <Text
          as={'div'}
          color={color}
          dangerouslySetInnerHTML={{
            __html: promotion.text,
          }}
        ></Text>
      </HStack>
      {url && showCta && (
        <Button as={Link} color="orange.500" to={url} _hover={{ textDecoration: 'none' }}>
          {buttonText ? buttonText : t('promotions:go')}
        </Button>
      )}
    </InfoCard>
  );
};
