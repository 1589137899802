import { Button, Flex, Icon, Stack, Text, Tooltip } from '@chakra-ui/react';
import { isNil } from 'lodash';
import * as React from 'react';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowDownward, MdList } from 'react-icons/md';
import { Navigate } from 'react-router-dom';

import { PartialStateCode, ServiceActivity, Vehicle } from '../../api/types';
import { SingleDatepicker } from '../../components/DatePicker';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { StatusTag } from '../../components/StatusTag';
import { DataTable } from '../../components/Table/DataTable';
import { DropdownOption, SelectColumnFilter } from '../../components/Table/Filter';
import { useUserContext } from '../../context/UserContextProvider';
import { dateTimeTransformer } from '../../helpers/dateTransformer';
import { createDropdownPlainOptions, useIsWorkshopBlocked } from '../../helpers/general';
import useServiceActivityQuery from '../../hooks/queries/service/useServiceActivityQuery';
import { ContentCard } from '../../layout/ContentCard';
import Link from '../../layout/Link';

import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { downloadFileFromUrl } from 'helpers/downloadFiles';

const ServiceActivityOverviewCard = () => {
  const minSizeOfVehicleModelName = 19;
  const { t } = useTranslation();
  const userContext = useUserContext();
  const axios = useAxios();
  const timezone = userContext.user?.timezone || 'Europe/Vienna';
  const statusCodes = userContext.statusCodes;
  const workshop = userContext.workshop;
  const serviceRecordCodes = userContext.statusCodes?.service_record;

  const activityOptions = createDropdownPlainOptions({
    records: [
      {
        value: 'service_record',
        label: t('components:service_process_card.service_record.label'),
      },
      {
        value: 'service_history_request',
        label: t('components:service_process_card.service_history_request.label'),
      },
      {
        value: 'inspection_plan',
        label: t('components:service_process_card.inspection_plan.label'),
      },
    ],
    valueColumn: 'value',
    labelColumn: 'label',
    t,
  });
  const getStatusOptions = (
    type: string,
    statusCodes: PartialStateCode<string>,
  ): Array<DropdownOption> => {
    let records: { value: string; label: string }[] = [];

    Object.keys(statusCodes).map((key) => {
      records.push({
        value: key,
        label: t(`status:${type}.${statusCodes[key]}.label`),
      });
    });

    return createDropdownPlainOptions({
      initialOptions: { value: '', label: t('common:all') },
      valueColumn: 'value',
      labelColumn: 'label',
      records,
      t,
    });
  };

  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<SetStateAction<any>>();
  const [type, setType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const serviceActivityQuery = useServiceActivityQuery({
    searchString,
    page,
    itemsPerPage,
    status,
    type,
    startDate,
    endDate,
  });
  

  const initialLoad =
    searchString === '' &&
    isNil(status) &&
    isNil(type) &&
    isNil(startDate) &&
    isNil(endDate);

  const serviceActivityData = serviceActivityQuery.data?.data || [];
  const currentPage = serviceActivityQuery.data?.meta?.current_page
    ? serviceActivityQuery.data?.meta?.current_page - 1
    : 1;

  useEffect(() => {
    setPage(1);
  }, [status, type, searchString, startDate, endDate, itemsPerPage]);

  const customGlobalFilter = (
    <>
      <SingleDatepicker
        date={startDate}
        onDateChange={setStartDate}
        maxDate={new Date()}
        placeholder={t('forms:start_date.placeholder')}
        configs={{ dateFormat: 'dd.MM.yyyy' }}
      />
      <SingleDatepicker
        date={endDate}
        minDate={startDate}
        maxDate={new Date()}
        placeholder={t('forms:end_date.placeholder')}
        onDateChange={setEndDate}
        configs={{ dateFormat: 'dd.MM.yyyy' }}
      />
    </>
  );
  let fields = [
    {
      Header: t('forms:id.label'),
      accessor: 'vehicle',

      Cell: (cellProps: { row: { original: ServiceActivity; index: number } }) => {
        const { row } = cellProps;
        const type = row.original.type;
        // @ts-ignore
        const record = row.original[type];

        return type !== 'service_record' ? (
          record?.user_readable_id
        ) : (
          <Link
            data-test-id={'edit-' + row.index}
            to={`/app/w/service-record/${row.original?.service_record?.id}?vin=${record?.vehicle?.vin}`}
          >
            {record?.user_readable_id}
          </Link>
        );
      },
    },
    {
      Header: t('common:date'),
      accessor: (row: { date: string }) => dateTimeTransformer(row.date, timezone, false),
    },
    {
      Header: t('forms:activity.label'),
      accessor: 'type',
      shouldFilter: true,

      Filter: ({
        column,
      }: {
        column: { setFilter: SetStateAction<any>; id: string; Header: string };
      }) =>
        SelectColumnFilter({
          filterValue: type,
          column,
          dropdownOptions: activityOptions,
          onChange: setType,
        }),

      Cell: ({ value }: { value: string }) =>
        value ? t(`components:service_process_card.${value}.label`) : t('common:unknown'),
    },
    {
      Header: t('forms:vin.label'),
      accessor: (row: { service: { vehicle: Vehicle } }) => {
        // @ts-ignore
        const info = row[row.type];

        return (
          <Link className="pii" to={`/app/w/vehicle?vin=${info?.vehicle?.vin}`}>
            {info?.vehicle?.vin}
          </Link>
        );
      },
    },
    {
      Header: t('forms:model.label'),
      accessor: (row: { service: { vehicle: Vehicle } }) => {
        // @ts-ignore
        const info = row[row.type];

        const vehicleTypeName = info?.vehicle?.vehicle_type?.fullname;
        return (
          vehicleTypeName 
            ? (
              vehicleTypeName.length < minSizeOfVehicleModelName 
                ? vehicleTypeName 
                : <Tooltip label={vehicleTypeName}>
                    <Text>{vehicleTypeName.slice(0, minSizeOfVehicleModelName)}...</Text>
                  </Tooltip>
              ) 
            : '-'
        );
      },
        
    },

    {
      Header: t('forms:status.label'),
      shouldFilter: true,
      Filter: ({
        column,
      }: {
        column: { setFilter: SetStateAction<any>; id: string; Header: string };
      }) =>
        SelectColumnFilter({
          filterValue: status,
          column,
          dropdownOptions: getStatusOptions('service_record', serviceRecordCodes ?? {}),
          onChange: setStatus,
        }),
      accessor: (row: { type: string }) => {
        const type = row.type;
        // @ts-ignore
        const record = row[type];
        return (
          <StatusTag
            //@ts-ignore
            statusCodes={statusCodes[type] ?? serviceRecordCodes}
            hideDescription
            currentStatus={type !== 'inspection_plan' ? record?.status : 700}
            resourceKey={type !== 'inspection_plan' ? type : 'service_record'}
          />
        );
      },
    },

    {
      id: 'button',
      accessor: 'vehicle',

      Cell: (cellProps: { row: { original: ServiceActivity } }) => {
        const { row } = cellProps;
        const type = row.original.type;
        // @ts-ignore
        const record = row.original[type];
      
        if(type === 'inspection_plan') {
         const downloadType = record?.status === 0 ? 'blank-download' : 'prefilled-download';
         return (
          <Flex gap={4}>
          <Button
            leftIcon={<MdArrowDownward />}
            variant="primary"
            size="sm"
            ml={'auto'}
            data-test-id={`${type}-${record?.id}-download-button`}
            onClick={() =>
              downloadFileFromUrl(
                axios,
                `${config.apiBaseUrl}/workshop/vehicles/${record?.vehicle?.vin}/inspection-plans/${record?.id}/${downloadType}`,
                'Inspection Plan.pdf',
              )
            }
          >
            {t('common:download')}
          </Button>
        </Flex>
         )
        } 
        if(type === 'service_history_request' && record?.status === 700) {
          return (
            <Flex gap={4}>
            <Button
              leftIcon={<MdArrowDownward />}
              variant="primary"
              size="sm"
              ml={'auto'}
              data-test-id={`service_history_request-${record?.id}-download-button`}
              onClick={() => downloadFileFromUrl(
                axios,
                `${config.apiBaseUrl}/workshop/service-history-requests/${record?.id}/history-download`,
                'Service History.pdf',
              )}
            >
              {t('common:download')}
            </Button>
          </Flex>
           )
        }
        if(type === 'service_record' && record?.status === 700) {
          return (
            <Flex gap={4}>
            <Button
              leftIcon={<MdArrowDownward />}
              variant="primary"
              size="sm"
              ml={'auto'}
              data-test-id={`service_record-${record?.id}-download-button`}
              onClick={() => downloadFileFromUrl(
                axios,
                `${config.apiBaseUrl}/workshop/service-records/${record?.id}/confirmation-download`,
                'Service Record.pdf',
              )}
            >
              {t('common:download')}
            </Button>
          </Flex>
           )
        }
      },
    },
  ];
  const columns = React.useMemo(
    () => fields,
    [
      serviceRecordCodes,
      searchString,
      type,
      status,
      startDate,
      initialLoad,
      endDate,
      customGlobalFilter,
      workshop?.subscription?.active_addons,
    ],
  );
  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (serviceActivityQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <ContentCard
      isLoading={serviceActivityQuery.isFetching}
      icon={<Icon as={MdList} boxSize={10} />}
      minH="fit-content"
      header={t('pages:service_activity.overview.header')}
      contentDescription={t('pages:service_activity.overview.description')}
      data-test-id="service-activity-overview-card"
    >
      <Stack direction={['column', 'row']} w={'100%'}>
        <Stack direction="column" w={'100%'}>
          {initialLoad && serviceActivityData.length === 0 ? (
            <Text>{t('pages:service_activity.overview.no_content')}</Text>
          ) : (
            <DataTable
              resetFilter={() => {
                setSearchString('');
                setPage(1);
                setItemsPerPage(10);
                setStatus(undefined);
                setStartDate(undefined);
                setEndDate(undefined);
                setType(undefined);
              }}
              data-test-id="service-activity-table"
              globalFilter={searchString}
              setGlobalFilter={setSearchString}
              globalFilterPlaceholder={t('pages:service_activity.overview.search')}
              customGlobalFilter={customGlobalFilter}
              hasFilters={true}
              currentPage={currentPage}
              perPage={itemsPerPage}
              total={serviceActivityQuery.data?.meta?.last_page || 1}
              columns={columns}
              setPage={setPage}
              setPerPage={setItemsPerPage}
              loading={serviceActivityQuery.isFetching}
              data={serviceActivityData}
              isMoreThenOnePage={
                Boolean(serviceActivityQuery.data?.meta?.next_page_url) || currentPage > 0
              }
            />
          )}
        </Stack>
      </Stack>
    </ContentCard>
  );
};

export default ServiceActivityOverviewCard;
