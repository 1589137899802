import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { ArrayResults, SubscriptionPlan } from '../../api/types';
import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

async function getSubscriptionPlans(
  axios: AxiosInstance,
): Promise<ArrayResults<SubscriptionPlan>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/subscription/plans`);
  return data;
}

export default function usePlansQuery() {
  const { t } = useTranslation();
  const toast = useToast();
  const axios = useAxios();
  return useQuery(getKey(), () => getSubscriptionPlans(axios), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}

function getKey(): QueryKey {
  return ['AvailablePlans'];
}

usePlansQuery.getKey = getKey;
