import { Button, FormControl, Grid, Icon, Input, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContentCard } from 'layout/ContentCard';
import { upperCase } from 'lodash';
import * as React from 'react';
import { ChangeEvent, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdManageSearch } from 'react-icons/md';
//@ts-ignore
import useGeoLocation from 'react-ipgeolocation';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Methods } from '../../api/types';
import { InfoCard } from '../../components/InfoCard';
import { ButtonSpinner } from '../../components/LoadingSpinner';
import config from '../../config';
import { useAxios } from '../../context/./AxiosContextProvider';
export interface IVehicleIdentificationForm {
  vin: string;
}

const formWidth = {
  base: 'full',
  md: '75%',
  lg: '50%',
};
const VehicleIdentificationFormCard = () => {
  const { t } = useTranslation();
  const schema = yup
    .object({
      vin: yup
        .string()
        .trim()
        .matches(/([0-9ABCDEFGHJKLMNPRSTUVWXYZ]{16,17})\w+/g, {
          message: t('forms:vin.error'),
        })
        .required(),
    })
    .required();
  const navigate = useNavigate();
  const axios = useAxios();
  const url = '/app/w/vehicle?vin=';
  const {
    handleSubmit,
    formState: { errors, isValid },
    register,
    setValue,
    setError,
  } = useForm<IVehicleIdentificationForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const [actualVin, setActualVin] = useState('');
  const [vinCouldBeInvalid, setVinCouldBeInvalid] = useState(false);

  const sendRequest = async ({
    url,
    method = 'post',
    dataForEndpoint = {},
    params = {},
  }: {
    url: string;
    method?: Methods;
    dataForEndpoint?: object;
    params?: object;
  }) => {
    const configData = {
      params,
    };
    const axiosCall =
      method !== 'get'
        ? axios[method](`${config.apiBaseUrl}/${url}`, dataForEndpoint, configData)
        : axios[method](`${config.apiBaseUrl}/${url}`, configData);
    return await axiosCall.catch((err: { response: { status: number } }) => {
      return Promise.reject(err);
    });
  };

  const { mutate, isLoading } = useMutation<any, Error, IVehicleIdentificationForm>(
    async (data) =>
      sendRequest({
        method: 'get',
        url: `workshop/vehicles/${data.vin}/validate`,
      }),
  );

  const navigateToPassedVin = (vin: string): void =>
    navigate(url + vin, { state: { fromIdentification: true } });

  const onSubmit: SubmitHandler<IVehicleIdentificationForm> = (data) => {
    mutate(data, {
      onSuccess: (validCheckData) => {
        const {
          // eslint-disable-next-line
          data: { check_digit_valid },
        } = validCheckData;

        setActualVin(data.vin);

        // Uncomment this, when you want to reactivate vinValidation
        /* if (!check_digit_valid && !vinCouldBeInvalid) {
          setVinCouldBeInvalid(true);
          return;
        }
        setVinCouldBeInvalid(false);*/
        // navigateToPassedVin(data.vin);
        navigate(url + data.vin, { state: { fromIdentification: true } });
      },
      onError: (error: any) => {
        const { errors, message } = error.response.data;

        setError('vin', {
          type: 'server',
          message: message ?? errors['vin']!.join('. '),
        });
      },
    });
  };

  const { onChange: onChangeVin } = register('vin');
  const changeVinAndValidate = useCallback(
    (event: ChangeEvent) => {
      vinCouldBeInvalid && setVinCouldBeInvalid(false);
      onChangeVin(event);
    },
    [vinCouldBeInvalid],
  );
  /*  const focusAnimationKeyframes = keyframes`
  50% { transform: scale(1.25); box-shadow: 0 0 1rem .5rem #FBD38D} 
  100% { transform: scale(1); box-shadow: none}
`;*/
  return (
    <ContentCard
      header={t('pages:vehicle_identification.card.header')}
      contentDescription={t('pages:vehicle_identification.card.description')}
      icon={<Icon as={MdManageSearch} boxSize={'16'} />}
      className={'tour-vehicle-card'}
    >
      {vinCouldBeInvalid ? (
        <InfoCard>
          <Text fontSize="sm" color="muted" data-test-id="possible-invalid-vin-caption">
            {t('forms:vin.validation.possibly_invalid')}
          </Text>
          <Button
            type="button"
            variant="outline"
            disabled={!isValid || isLoading}
            data-test-id="possible-invalid-vin-proceed-button"
            onClick={() => {
              navigateToPassedVin(actualVin);
            }}
          >
            {t('common:proceed')} {isLoading ? <ButtonSpinner /> : null}
          </Button>
        </InfoCard>
      ) : null}

      <Stack direction={['column', 'row']} w={'full'} justifyContent={'center'}>
        <Grid
          id={'vehicle-identification-anchor'}
          as="form"
          w={formWidth}
          templateColumns={{ base: 'auto', lg: 'auto' }}
          gap={4}
          p={4}
          textAlign={'center'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack direction={'column'} spacing={6} p={{ base: 0, lg: 4 }}>
            <FormControl id="vin" isInvalid={!!errors.vin?.message}>
              <Input
                type="text"
                // animation={`${focusAnimationKeyframes} 2s ease-in-out`}
                className={'vin-input pii'}
                textAlign={'center'}
                placeholder={t('forms:vin.placeholder')}
                _placeholder={{ opacity: 0.5 }}
                errorBorderColor="status.error"
                {...register('vin')}
                onInput={(e) =>
                  //@ts-ignore
                  setValue('vin', upperCase(e.target.value).replace(/ /g, ''))
                }
                onChange={changeVinAndValidate}
                maxLength={17}
              />
              <Text
                fontSize="sm"
                color={
                  errors.vin?.message === t('forms:vin.error') ? 'gray.600' : 'error'
                }
              >
                {errors.vin?.message}
              </Text>
            </FormControl>

            <Button
              type="submit"
              variant="primary"
              disabled={isLoading || vinCouldBeInvalid}
              /*       disabled={!isValid || isLoading || vinCouldBeInvalid}*/
              data-test-id="identification-manual-submit-button"
              alignSelf={'center'}
            >
              {t('pages:vehicle_identification.identify')}{' '}
              {isLoading ? <ButtonSpinner /> : null}
            </Button>
          </Stack>
        </Grid>
      </Stack>
    </ContentCard>
  );
};

export default VehicleIdentificationFormCard;
