import { useToast } from '@chakra-ui/react';
import { Addon, ArrayResults } from 'api/types';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import config from '../../config';
import { DURATION } from '../../constants';
import { useAxios } from '../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../helpers/manageErrorResponse';

async function getAddonData(axios: AxiosInstance): Promise<ArrayResults<Addon>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/subscription/addons`);
  return data;
}

export default function useAddonsQuery() {
  const { t } = useTranslation();
  const toast = useToast();
  const axios = useAxios();
  return useQuery('Addons' as QueryKey, () => getAddonData(axios), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
useAddonsQuery.getKey = () => 'Addons';
