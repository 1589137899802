import { Box, Button, Flex, Grid, HStack, Icon, Select, Stack, Text, useToast, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownOption } from 'components/Table/Filter';
import { queryClient } from 'configs/queryClient';
import { addDays } from 'date-fns';
import { getVehicleTypeOptions } from 'helpers/general';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import useUpdateVehicleMutation from 'hooks/private/mutations/useUpdateVehicleMutation';
import useMaintenanceSystemQuery from 'hooks/queries/service/useMaintenanceSystemQuery';
import useVehicleQuery from 'hooks/queries/useVehicleQuery';
import useVehicleModelsQuery from 'hooks/queries/workshop/useVehicleModelsQuery';
import useVehicleModelTypesQuery from 'hooks/queries/workshop/useVehicleModelTypesQuery';
import { ContentCard } from 'layout/ContentCard';
import { get } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdEditNote } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { VehicleModel } from '../../api/types';
import { ButtonSpinner } from '../../components/LoadingSpinner';
import { onClickGridDatePicker } from '../../helpers/makeFormFields';

export interface IVehicleIdentificationForm {
  vin: string;
  initial_registration_date: Date | null;
  hp_model_id: string | null;
  hp_type_id: string | null;
}

const VehicleUpdateModal = ({ isModal, onClose }: { isModal?: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const schema = yup
    .object({
      initial_registration_date: yup
        .date()
        .required(t('forms:initial_registration_date.required'))
        .label(t('forms:initial_registration_date.label'))
        .max(addDays(new Date(), 1), t('forms:initial_registration_date.max'))
        .typeError(t('forms:date.type_error')),
      placeholder_initial_registration_date: yup.date().nullable(),
      hp_model_id: yup.string().required(t('forms:vehicle_info.model_required')).nullable(),
      hp_type_id: yup.string().when('hp_model_id', {
        is: (value: string) => value !== 'unknown',
        then: yup.string().required(t('forms:vehicle_info.type_required')).nullable(),
        otherwise: yup.string().nullable(),
      }),
    })
    .required();
  const navigate = useNavigate();
  const toast = useToast();
  const urlParams = new URLSearchParams(window.location.search);
  const vinParams = urlParams.get('vin') || '';
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm<IVehicleIdentificationForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const watchModel = watch('hp_model_id');
  const [actualVin, setActualVin] = useState('');
  const [actualInitialRegistrationDate, setActualInitialRegistrationDate] = useState('');

  // model set on the vehicle identification if exists
  const [selectedModelId, setSelectedModelId] = useState('');
  const [selectedTypeId, setSelectedTypeId] = useState('');

  // update vehicle mutation
  const updateVehicleMutation = useUpdateVehicleMutation(actualVin);

  // vehicle data
  const vehicleQuery = useVehicleQuery(actualVin);
  const vehicleData = vehicleQuery.data?.data;

  const initialRegistrationDate = vehicleData?.initial_registration_date;

  // vehicle models data
  const vehicleModelsQuery = useVehicleModelsQuery(actualVin);
  const vehicleModels = vehicleModelsQuery.data?.data;

  const [selectedDate, setSelectedDate] = useState(null);
  const [currentView, setCurrentView] = useState('year');
  const [chosenYear, setChosenYear] = useState(1970);
  const [partiallyDateSaved, setPartiallyDateSaved] = useState([]);
  const updatePartiallyDateSaved = (newDate) => {
    setPartiallyDateSaved((prev) => [...prev, newDate]);
  };

  useEffect(() => {
    setValue('initial_registration_date', initialRegistrationDate ?? '-');
  }, [initialRegistrationDate]);

  useEffect(() => {
    setActualVin(vinParams);
    setValue('vin', vinParams);
  }, [vinParams]);

  // vehicle type data
  const vehicleModelTypesQuery = useVehicleModelTypesQuery(actualVin, parseInt(selectedModelId));
  const vehicleModelTypes = vehicleModelTypesQuery.data?.data;
  // vehicle type data

  const vehicleTypeOptionesGrouped = getVehicleTypeOptions({
    vehicleTypes: vehicleModelTypes,
    t,
  });

  const onTypeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTypeId(event.target.value);
    setValue('hp_type_id', event.target.value);
    clearErrors('hp_type_id');
  };

  // on fetched vehicle data, set the initial registration date in local state and form
  useEffect(() => {
    if (vehicleData?.initial_registration_date) {
      setActualInitialRegistrationDate(vehicleData.initial_registration_date);
      setValue('initial_registration_date', new Date(vehicleData.initial_registration_date));
    }
    if (vehicleData?.hp_model_id) {
      setSelectedModelId(vehicleData.hp_model_id);
      setValue('hp_model_id', vehicleData.hp_model_id);
    }
    if (vehicleData?.hp_type_id && vehicleData.hp_type_id !== 'null') {
      setSelectedTypeId(vehicleData.hp_type_id);
      setValue('hp_type_id', vehicleData.hp_type_id);
    }
  }, [vehicleData]);

  const isEnabledInitalRegistrationDate = vehicleData?.initial_registration_date === null || actualInitialRegistrationDate;

  const onSubmit: SubmitHandler<IVehicleIdentificationForm> = (data) => {
    // Convert the date to a UTC Date object
    const localDate = new Date(data.initial_registration_date); // Converts to local time Date
    // Create a UTC Date using UTC values
    const utcDate = new Date(
      Date.UTC(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate(),
        localDate.getHours(),
        localDate.getMinutes(),
        localDate.getSeconds(),
      ),
    );

    const dataForEndpoint = {
      vehicle_type_id: data.hp_type_id,
      initial_registration_date: utcDate.toISOString(), // Convert to ISO string for API
    };
    updateVehicleMutation.mutate(dataForEndpoint, {
      onSuccess: () => {
        queryClient.invalidateQueries(useMaintenanceSystemQuery.getKey(vinParams));
        onClose();
        window.history.replaceState({}, '');
        navigate(`/app/w/vehicle?vin=${data.vin}`);
      },
      onError: (err: any) => {
        manageErrorResponse({ toastInstance: toast, error: err.response, t });
      },
    });
  };
  const handleDateChange = (date) => {
    if (date) {
      // Create a new date set to UTC
      const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

      // Update the form value with the UTC date
      setValue('initial_registration_date', utcDate, { shouldValidate: true });
      clearErrors('initial_registration_date');
    }
  };
  const unknownModel = {
    engine_code: null,
    fullname: t('forms:model.unknown'),
    hp_id: 'unknown',
    name: '',
    output: null,
  };

  const unknownType = {
    label: t('forms:type.unknown_group'),
    options: [
      {
        label: t('forms:type.unknown'),
        value: 'unknown',
      },
    ],
  };

  return (
    <ContentCard
      header={t('forms:vehicle_info.header')}
      contentDescription={`${t('forms:vehicle_info.content')} `}
      icon={<Icon as={MdEditNote} boxSize={'16'} />}
      className={'tour-vehicle-card'}
      style={isModal ? { boxShadow: 'none' } : {}}
      m={isModal ? -6 : 0}
    >
      <VStack minHeight={480}>
        <Grid as="form" width={'90%'} templateColumns={{ base: 'auto', lg: 'auto' }} gap={4} p={4} onSubmit={handleSubmit(onSubmit)}>
          <Stack w={'100%'}>
            {onClickGridDatePicker({
              name: 'initial_registration_date',
              label: t('forms:initial_registration_date.label'),
              placeholder: t('forms:initial_registration_date.select_date.placeholder'),
              selectedDate: selectedDate ? selectedDate : initialRegistrationDate ? initialRegistrationDate : selectedDate,
              setSelectedDate: (date) => {
                setSelectedDate(date);
                handleDateChange(date);
              },
              currentView: currentView,
              setCurrentView: setCurrentView,
              control,
              errors,
              schema,
              register,
              chosenYear: chosenYear,
              setChosenYear: setChosenYear,
              partiallyDateSaved: partiallyDateSaved,
              updatePartiallyDateSaved: updatePartiallyDateSaved,
            })}
          </Stack>

          <VStack spacing={10} mt={1}>
            <Stack w={'100%'} style={{ marginBottom: -10 }}></Stack>
            <Flex direction={'column'} flex={1} alignItems={'flex-start'} style={{ width: '100%' }}>
              <Text fontSize={'sm'} mb={3}>
                {t('forms:model.label')}{' '}
              </Text>
              <Select
                placeholder={
                  selectedModelId
                    ? `${vehicleModels?.find((model: VehicleModel) => model?.hp_id === parseInt(selectedModelId))?.fullname}`
                    : t('forms:model_type_id.select_model')
                }
                defaultValue={selectedModelId || ''}
                className={'tour-model-selection'}
                aria-label={'vehicle-types'}
                data-test-id="vehicle-type-dropdown"
                {...register('hp_model_id')}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  setSelectedModelId(selectedValue);
                  setValue('hp_model_id', selectedValue);
                  if (selectedValue === 'unknown') {
                    setSelectedTypeId('unknown');
                    setValue('hp_type_id', 'unknown');
                  } else {
                    setSelectedTypeId('');
                    setValue('hp_type_id', '');
                  }
                  clearErrors('hp_model_id');
                }}
              >
                {vehicleModels &&
                  [...vehicleModels, unknownModel]?.map((vehicle: any, index) => {
                    if (vehicle.hp_id === 'unknown') {
                      return (
                        <option value={vehicle.hp_id} key={index}>
                          {`${vehicle.fullname}`}{' '}
                        </option>
                      );
                    } else {
                      return (
                        <option value={vehicle.hp_id} key={index}>
                          {`${vehicle.fullname} (${vehicle.made_from ?? '...'} - ${vehicle.made_until ?? '...'})`}
                        </option>
                      );
                    }
                  })}
              </Select>
              {errors.hp_model_id && (
                <Text color="red.500" fontSize="sm">
                  {errors.hp_model_id.message}
                </Text>
              )}
            </Flex>
            <Flex direction={'column'} flex={1} alignItems={'flex-start'} style={{ width: '100%', marginTop: 35 }}>
              <Text fontSize={'sm'} mb={1}>
                {t('forms:type.label')}
              </Text>

              <Select
                // @ts-ignore
                placeholder={
                  vehicleModelTypesQuery.isFetching
                    ? t('common:loading')
                    : watchModel === 'unknown'
                      ? t('forms:type.unknown')
                      : selectedTypeId
                        ? `${vehicleModelTypes?.find((model) => model?.hp_id === parseInt(selectedTypeId))?.engine_code} / ${
                            vehicleModelTypes?.find((model) => model?.hp_id === parseInt(selectedTypeId))?.output
                          }`
                        : t('forms:vehicle_type_id.select_type')
                }
                disabled={vehicleModelTypesQuery.isFetching || !selectedModelId || watchModel === 'unknown'}
                className={'tour-model-selection'}
                aria-label={'vehicle-types'}
                data-test-id="vehicle-type-dropdown"
                {...register('hp_type_id')}
                onChange={onTypeSelect}
              >
                {vehicleModelTypesQuery.isFetching ? (
                  <ButtonSpinner />
                ) : (
                  vehicleTypeOptionesGrouped &&
                  [...vehicleTypeOptionesGrouped, unknownType]?.map((option, index) => (
                    <optgroup label={option.label} key={index}>
                      {get(option, 'options', []).map((subOption: DropdownOption, subIndex: number) => (
                        <option value={subOption.value} key={subIndex}>
                          {subOption.label}
                        </option>
                      ))}
                    </optgroup>
                  ))
                )}
              </Select>

              {errors.hp_type_id && (
                <Text color="red.500" fontSize="sm">
                  {errors.hp_type_id.message}
                </Text>
              )}
            </Flex>
          </VStack>
          <HStack justify="center" align="center">
            <Button
              type="submit"
              variant="primary"
              disabled={errors.hp_model_id || errors.hp_type_id || errors.initial_registration_date}
              data-test-id="identification-manual-submit-button"
              alignSelf={'center'}
              style={{ marginTop: 30 }}
            >
              {t('common:save')}{' '}
              {updateVehicleMutation.isLoading ? (
                <Box ml={5}>
                  {' '}
                  <ButtonSpinner />{' '}
                </Box>
              ) : null}
            </Button>
          </HStack>
        </Grid>
      </VStack>
    </ContentCard>
  );
};

export default VehicleUpdateModal;
