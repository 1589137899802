import { useToast } from '@chakra-ui/react';
import { AxiosError, AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  ActivatedAddon,
  BILLING_CYCLE,
  Charge,
  Conversation,
  Distributor,
  FileObject,
  Results,
  SubscriptionPlan,
  SystemPartner,
} from '../../../api/types';
import config from '../../../config';
import { DURATION } from '../../../constants';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';
import useTokenQuery from '../useTokenQuery';

export type Verification = {
  id: number;
  status: number;
  status_reason: number;
  comment: string;
  legacy: boolean;
  processed_at: string;
  workshop_id: number;
  power_of_attorney: FileObject;
  business_licence: FileObject;
  identification: FileObject;
  power_of_attorney_file_id: number;
  business_licence_file_id: number;
  identification_file_id: number;
  user_id: number;
  workshop_update_request_id: number | null;
};

export const SUBSCRIPTION_ACTIVE = 200;
export const SUBSCRIPTION_NON_RENEWAL = 300;
export const SUBSCRIPTION_CANCELLED = 500;

export type Subscription = {
  plan_id: number;
  plan: SubscriptionPlan;
  billing_cycle: BILLING_CYCLE.MONTHLY | BILLING_CYCLE.YEARLY;
  active_addons: { [key: string]: ActivatedAddon };
  charges: Charge[];
  status: number;
  ended_at?: string;
  updated_at: string;
  created_at: string;
};

export type WorkshopUpdateRequest = {
  id: number;
  status: number;
  old_values: string;
  new_values: string;
  workshop_id: number;
  workshop_user_id: number;
  user_id: number;
  consent_charge: boolean;
  submitted_at: string;
  updated_at: string;
  conversation: Conversation;
};

type Distributor = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  slug: string;
  coupon: string;
  logo_url: string;
  setup_fee_exempt: boolean;
  comission_granted: boolean;
  prepaid_plan_id: number;
  prepaid_plan: number;
  prepaid_plan_amount_monthly: number;
  additional_monthly_sr_quota: {
    additional: number;
    start: string;
    end: string;
  } | null;
  additional_monthly_shr_quota: {
    additional: number;
    start: string;
    end: string;
  } | null;
};

export type WorkshopData = {
  units: string;
  currency: string | undefined;
  billing_email: string;
  id: number;
  created_at: any;
  updated_at: any;
  firstname: string;
  lastname: string;
  email: string;
  registration_key?: string;
  telephone: string;
  name: string;
  has_failed_payments: boolean;
  status: number;
  email_verified: boolean;
  distributor_id?: string;
  vat_number?: string;
  country: string;
  language: string;
  city: string;
  zipcode: string;
  address: string;
  is_legacy: boolean;
  partner_id?: number;
  promotional_credits: string;
  is_verified?: boolean;
  is_verification_pending?: boolean;
  subscription: Subscription;
  verifications: Array<Verification>;
  update_requests: Array<WorkshopUpdateRequest>;
  user_readable_id: string;
  wordpress_user_id?: string | null;
  wp_wallet_balance: number;
  disable_cost_control: boolean;
  consent_termsandconditions: boolean;
  consent_dataprivacy: boolean;
  consent_newsletter: boolean;
  locked: boolean;
  pending_data_update_request: null | WorkshopUpdateRequest;
  distributor: null | Distributor;
  systempartner: SystemPartner;
};

export interface IWorkshopQueryOptions {
  options?: UseQueryOptions<WorkshopData, AxiosError>;
}

async function getWorkshop(axios: AxiosInstance): Promise<Results<WorkshopData>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop`);

  return data;
}

export default function useWorkshopQuery() {
  const { t } = useTranslation();
  const axios = useAxios();
  const toast = useToast();

  return useQuery(getWorkshopKey(), () => getWorkshop(axios), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: false,
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
function getWorkshopKey(): QueryKey {
  return ['Workshop'];
}

useWorkshopQuery.getKey = getWorkshopKey;

export function useWorkshopPollingQuery() {
  const { t } = useTranslation();
  const toast = useToast();
  const axios = useAxios();
  const navigate = useNavigate();
  const tokenQuery = useTokenQuery();
  return useQuery(getWorkshopPollingQueryKey(), () => getWorkshop(axios), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    retry: 6,
    refetchOnWindowFocus: false,
    enabled: !!tokenQuery.data,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
      if (error.response.status >= 500) {
        navigate('/app/error', { replace: true });
      }
    },
  });
}

function getWorkshopPollingQueryKey(): QueryKey {
  return ['WorkshopPolling'];
}

useWorkshopPollingQuery.getKey = getWorkshopPollingQueryKey;
