import { Box, Button, Grid, Heading, Icon, Text } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { CornerRibbon } from 'components/CornerRibbon';
import { get, isEmpty, isNil, noop } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ActivatedAddon, ADDON_ICONS, BILLING_CYCLE, LooseObject, Results, SubscriptionAddon } from '../../../../api/types';
import GetAddonModalData from '../../../../components/modals/GetAddonModalData';
import PollingAlertModal from '../../../../components/modals/PollingAlertModal';
import PriceInCard from '../../../../components/PriceInCard';
import { FONT_WEIGHTS } from '../../../../global/Fonts';
import { dateTimezoneTransformer } from '../../../../helpers/dateTransformer';
import { Subscription, WorkshopData } from '../../../../hooks/queries/workshop/useWorkshopQuery';

const stateStyles = {
  isActive: {
    backgroundColor: 'gray.300',
    color: 'accent',
  },
  isSelected: {
    backgroundColor: 'accent',
    color: 'white',
  },
  isHovered: {
    backgroundColor: 'gray.200',
    color: 'accent',
  },
  isDisabled: {
    backgroundColor: 'gray.300',
    color: 'accent',
  },
  default: {
    color: 'accent',
    backgroundColor: 'white',
  },
};

export interface IAddonProps {
  billingCycle: BILLING_CYCLE;
  currency: string | undefined;
  onChange?: (selectedPlanId: string) => void;
  customButtonText?: string;
  addon: SubscriptionAddon;
  subscription?: Subscription;
  isSelected?: boolean;
  addonActiveData?: ActivatedAddon | LooseObject;
  isHovered?: boolean;
  showStartingPrice?: boolean;
  noAddonPrompt?: boolean;
  costControlDisabled?: boolean;
  icon?: any;
}

export const Addon = (props: IAddonProps) => {
  const { t } = useTranslation();
  const {
    icon,
    addon,
    currency,
    costControlDisabled,
    subscription,
    billingCycle,
    isSelected = false,
    isHovered = false,
    addonActiveData = {},
    noAddonPrompt = false,
  } = props;

  const isActive = !isEmpty(addonActiveData);
  const isTrialPurchased = !isNil(addonActiveData?.trial_ends);
  const getStateStyles = (stateKey: string) => {
    return get(stateStyles, stateKey, stateStyles.default);
  };

  const componentStyles = isActive
    ? getStateStyles('isActive')
    : isSelected
      ? getStateStyles('isSelected')
      : isHovered
        ? getStateStyles('isHovered')
        : getStateStyles('default');
  const price = addon?.price[billingCycle];

  const isOnPromotion = isActive ? false : !isEmpty(price?.discount);

  const isTrialOn = !isOnPromotion && (addon?.trial_available || (isActive && addonActiveData?.trial));

  const isPurchased = !isEmpty(addonActiveData) && !isTrialPurchased;
  let variant = isPurchased ? 'outline' : 'primary';

  let modalData = GetAddonModalData({
    currency,
    addon,
    activeAddon: addonActiveData,
    // @ts-ignore
    subscription: subscription || {},
    costControlDisabled,
  });

  if (isTrialPurchased) {
    modalData = {
      modalComponent: PollingAlertModal,
      pollingCondition: (data: Results<WorkshopData>) => {
        const activeAddons = get(data?.data?.subscription, 'active_addons', {});
        if (Array.isArray(activeAddons)) {
          return true;
        } else {
          return !get(activeAddons, addon?.id);
        }
      },
      showSuccessAnimation: false,
      // @ts-ignore
      data: { addon_id: addon?.id },
      content: {
        header: t('addons:trial.modal.cancel.header'),
        description: t('addons:.modal.cancel.description'),
        footer: {
          // @ts-ignore
          buttons: {
            cancelCaption: t('pages:subscription.manage.update.addons.keep'),
            actionCaption: t('pages:subscription.manage.update.addons.remove'),
          },
        },
      },
      url: `workshop/subscription/addons/${addon?.id}/cancel`,
    };
  }
  let onClickFunction = isPurchased ? noop : () => NiceModal.show(modalData?.modalComponent, modalData);

  return (
    <Grid
      p={4}
      key={addon.id}
      h="full"
      {...componentStyles}
      gap={4}
      placeContent={'space-between'}
      wordBreak={'break-word'}
      textAlign={'left'}
      position={'relative'}
      flexDirection={'column'}
    >
      <Grid gap={6} alignContent={'flex-start'}>
        {isOnPromotion ? <CornerRibbon>{t('promotions:general.label')}</CornerRibbon> : null}
        {isPurchased || isTrialPurchased ? (
          <CornerRibbon backgroundColor={'brand.blue.default'}>
            {isTrialPurchased ? t('pages:subscription.manage.update.addons.active_trial') : t('pages:subscription.manage.update.addons.active')}
          </CornerRibbon>
        ) : null}
        {/*@ts-ignore*/}
        <Icon as={icon || ADDON_ICONS[addon.id]} boxSize="20" color={'accent'} />
        <Heading as={'h3'} lineHeight={1.2} fontWeight={FONT_WEIGHTS.bold} fontSize="lg">
          {t(`addons:${addon.id}.name`, addon.name)}
        </Heading>
        <Text fontSize="sm" color={'default'}>
          {t(`addons:${addon.id}.description`)}
        </Text>
      </Grid>
      <Grid gap={6} alignContent={'flex-start'}>
        {!isPurchased && !isTrialPurchased ? (
          <Box paddingTop={5}>
            <PriceInCard
              t={t}
              billingCycle={billingCycle}
              value={price?.amount}
              discount={price?.discount}
              isTrial={isTrialOn && !isPurchased && !isTrialPurchased}
              showStartingPrice={Boolean(subscription?.plan?.is_pay_per_use)}
            />
          </Box>
        ) : null}
        {!noAddonPrompt && !isPurchased && (
          <>
            <Button variant={variant} onClick={onClickFunction} width={250} mb={3}>
              {isTrialPurchased
                ? t('pages:subscription.manage.update.addons.remove')
                : isTrialOn
                  ? t('pages:subscription.manage.update.addons.trial')
                  : t('pages:subscription.manage.update.addons.purchase')}
            </Button>
            <Text fontSize={'13'} textAlign={'center'} width="100%" whiteSpace="nowrap" overflowWrap="break-word" wordBreak="keep-all" mt={-8}>
              {isTrialPurchased
                ? t(`addons:trial.end`, {
                    //@ts-ignore
                    end: dateTimezoneTransformer(addonActiveData?.trial_ends),
                  })
                : null}
            </Text>
          </>
        )}
      </Grid>
    </Grid>
  );
};
