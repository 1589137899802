import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { manageErrorResponse } from 'helpers/manageErrorResponse';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';
import config from 'service/../config';

import { Distributor, Results } from '../../api/types';
import { DURATION } from '../../constants';

async function getDistributor(id: number | undefined): Promise<Results<Distributor>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/distributor/` + id);
  return data;
}

export default function useDistributorQuery(id: number | undefined) {
  const toast = useToast();
  const { t } = useTranslation();
  return useQuery(getKey(id), () => getDistributor(id), {
    enabled: !!id,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}
function getKey(id: number | undefined): QueryKey {
  return ['Distributor', id];
}

useDistributorQuery.getKey = getKey;
