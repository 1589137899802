import { Box, Button, Center, Heading, Image, Text } from '@chakra-ui/react';
import useTokenQuery from 'hooks/queries/useTokenQuery';
import { noop } from 'lodash';
import Lottie from 'lottie-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import alexThumbsUp from '../../../assets/animations/alex-thumbs-up.json';
import Confetti from '../../../components/Confetti';
import { InlineLoadingSpinner, LoadingSpinner } from '../../../components/LoadingSpinner';
import { useUserContext } from '../../../context/UserContextProvider';
import useWorkshopQuery from '../../../hooks/queries/workshop/useWorkshopQuery';
import { Card } from '../../../layout/Card';
import { ExternalLink } from '../../../layout/Link';

const SUCCESS_ANIMATION_DURATION = 2500;

export const PollingCard = (props: {
  query?: any;
  pollingQuery?: any;
  noCard?: boolean;
  canGoBack?: boolean;
  allowRetry?: boolean;
  success?: () => void;
  showSuccessAnimation?: boolean;
  pollingCondition(data: any): boolean;
  retryInterval?: number;
  maxTries?: number;
}) => {
  const {
    canGoBack = false,
    allowRetry = false,
    showSuccessAnimation = false,
    query = useWorkshopQuery,
    noCard = false,
    pollingCondition,
    success = noop,
    retryInterval = 2000,
    maxTries = 20,
  } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tokenQuery = useTokenQuery();
  const token = tokenQuery.data;
  const userContext = useUserContext();
  const [refetchInterval, setRefetchInterval] = useState<boolean | number>(retryInterval);
  const [isSuccess, setIsSuccess] = useState(false);
  const [retryCount, setRetryCount] = useState(1);
  const queryClient = useQueryClient();
  queryClient.invalidateQueries(useWorkshopQuery.getKey());

  const { data } = query(token!, {
    enabled: !!token,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    refetchInterval,
    retryDelay: refetchInterval,
    retry: maxTries,
    onSettled: () => {
      setRetryCount(retryCount + 1);
      if (retryCount >= maxTries) {
        setRefetchInterval(false);
      }
    },
  });
  useEffect(() => {
    if (pollingCondition(data)) {
      setIsSuccess(true);
      new Promise((resolve) => {
        setTimeout(resolve, showSuccessAnimation ? SUCCESS_ANIMATION_DURATION : 0);
      }).then(() => {
        success();
      });
    }
  }, [data]);

  let content = (
    <Box data-test-id="polling-loading-content" p={10}>
      <Heading size={'md'}>{t('components:polling_card.loading.title')}</Heading>
      <InlineLoadingSpinner m={5} />
      <Text px={10} color="muted" fontSize="sm">
        {t('components:polling_card.loading.text')}
      </Text>
      <Center>
        <Image
          src="/images/alex-computer.svg"
          alt="Alex the mechanic in front of a computer"
          w={'30%'}
          h={'auto'}
        />
      </Center>
    </Box>
  );

  if (retryCount >= maxTries) {
    content = (
      <Box width={'full'} ml={2} data-test-id="polling-error-content" p={10}>
        <Heading p={10} size="md">
          {t('components:polling_card.error.title')}
        </Heading>
        <Text px={10} color="muted" fontSize="sm">
          <Trans i18nKey="components:polling_card.error.text" />
        </Text>
        {allowRetry ? (
          <Button
            my={5}
            onClick={() => {
              setRetryCount(1);
              setRefetchInterval(retryInterval);
            }}
            variant="primary"
            data-test-id="polling-retry-button"
          >
            {t('components:polling_card.error.retry')}
          </Button>
        ) : null}
        {canGoBack ? (
          <Button onClick={() => navigate(-1)} variant="outline" mr={1}>
            {t('common:back')}
          </Button>
        ) : null}
        <Text px={10} color="muted" fontSize="sm">
          {t('components:polling_card.error.support')}{' '}
          <ExternalLink
            isExternal
            href={`tel:${userContext.config?.data?.support_telephone}`}
          >
            {userContext.config?.data?.support_telephone}
          </ExternalLink>
        </Text>
      </Box>
    );
  }

  if (isSuccess && showSuccessAnimation) {
    content = (
      <Box data-test-id="polling-successful-content" p={10} position={'relative'}>
        <Heading size={'md'}>{t('components:polling_card.success.title')}</Heading>

        <Center>
          <Box w={'60%'}>
            <Lottie animationData={alexThumbsUp} loop={true} />
          </Box>
        </Center>
        <Confetti pos="fixed" bottom="0" left="15%" w={'70%'} h={'100%'} />
      </Box>
    );
  }
  if (tokenQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return noCard ? (
    <Box w={'100%'} textAlign="center">
      {content}
    </Box>
  ) : (
    <Card width={'60%'} textAlign="center">
      {content}
    </Card>
  );
};

export default PollingCard;
