import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { PaginatedArrayResults, ServiceRecord } from '../../../api/types';
import config from '../../../config';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function getServiceRecord(props: {
  axios: AxiosInstance;
  status?: number;
  sortBy?: string;
  sortOrder?: string;
  itemsPerPage?: number;
  page?: number;
}): Promise<PaginatedArrayResults<ServiceRecord>> {
  const { axios, status, sortBy, sortOrder, itemsPerPage, page } = props;
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/service-records`, {
    params: {
      status,
      sortBy,
      sortOrder,
      itemsPerPage,
      page,
    },
  });
  return data;
}

export default function useServiceRecordByStatusQuery({
  status,
  sortBy,
  sortOrder,
  itemsPerPage,
  page,
}: {
  status: number;
  sortBy?: string;
  sortOrder?: string;
  itemsPerPage?: number;
  page?: number;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const axios = useAxios();
  return useQuery(
    getKey({ status, sortBy, sortOrder, itemsPerPage, page }),
    () => getServiceRecord({ axios, status, sortBy, sortOrder, itemsPerPage, page }),
    {
      notifyOnChangeProps: ['data', 'isFetching', 'error'],
      retry: 6,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      onError: (error: any) => {
        manageErrorResponse({
          toastInstance: toast,
          error: JSON.stringify(error),
          hideRealResponse: true,
          t,
        });
      },
    },
  );
}

function getKey({
  status,
  sortBy,
  sortOrder,
  itemsPerPage,
  page,
}: {
  status?: number;
  sortBy?: string;
  sortOrder?: string;
  itemsPerPage?: number;
  page?: number;
}): QueryKey {
  return ['ServiceRecord', status, sortBy, sortOrder, itemsPerPage, page];
}

useServiceRecordByStatusQuery.getKey = getKey;
