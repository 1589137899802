import { Box, Button, Collapse, Divider, Heading, HStack, Icon, Square, Stack, Text, Tooltip, useBoolean } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import useDestroyInspectionPlanMutation from 'hooks/private/mutations/useDestroyInspectionPlanMutation';
import useSendDocumentsMutation from 'hooks/private/mutations/useSendDocumentsMutation';
import { find, get, isEmpty, merge } from 'lodash';
import * as React from 'react';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsCardChecklist } from 'react-icons/bs';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IoIosWarning } from 'react-icons/io';
import { RiMailSendLine } from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ADDON_KEYS, LooseObject, Service, StatusCodes, SubscriptionAddon } from '../../../api/types';
import { ServiceHistoryRequestIcon, ServiceRecordIcon } from '../../../components/Icons';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import AlertModal from '../../../components/modals/AlertModal';
import GetAddonModalData from '../../../components/modals/GetAddonModalData';
import { Skeleton } from '../../../components/Skeleton';
import { CardHeaderSkeleton } from '../../../components/SkeletonTemplates';
import { awaitAnimation } from '../../../configs/animations';
import { useUserContext } from '../../../context/UserContextProvider';
import { eventTrack } from '../../../helpers/analytics';
import dateTransformer from '../../../helpers/dateTransformer';
import UnlockFeature from '../../../helpers/unlockFeature';
import useCompleteServiceMutation from '../../../hooks/private/mutations/useCompleteServiceMutation';
import useDeleteDocumentMutation from '../../../hooks/private/mutations/useDeleteDocumentMutation';
import useUploadDocumentMutation from '../../../hooks/private/mutations/useUploadDocumentMutation';
import useAccountsQuery from '../../../hooks/queries/useAccountsQuery';
import { SERVICE_STATE } from '../../../hooks/queries/useVehicleQuery';
import useVehicleQuery from '../../../hooks/queries/useVehicleQuery';
import { WorkshopData } from '../../../hooks/queries/workshop/useWorkshopQuery';
import { Card } from '../../../layout/Card';
import { transformManufacturerAccounts } from '../../serviceRecord/AccountRegistrationStatusPage';
import { getCardColor, getInteractionContentBasedOnState, tool } from './displayHelper';
import DocumentsSection from './DocumentsSection';
import { ServiceStep } from './ServiceStep';
import { ServiceActivityTimeline } from './timeline/ServiceActivityTimeline';

const defaultStepState = {
  vehicle_identification: 'available',
  service_history_request: 'unavailable',
  inspection_plan: 'unavailable',
  service_record: 'unavailable',
  uploaded_documents: 'unavailable',
  sent_documents: 'unavailable',
};

type TransformedAccounts = {
  account: {
    status: number;
    can_access_dsb: true;
  };
  account_id: number;
  estimate: string;
  make_name: string;
};

const ServiceProcessCard = (props: {
  make: string | undefined;
  isLoading: boolean;
  state?: SERVICE_STATE;
  form?: string;
  hasCollapsableContent?: boolean;
  vin?: string;
  service?: Service | LooseObject;
  workshop: WorkshopData;
  addons: SubscriptionAddon[];
  statusCodes?: StatusCodes;
  features?: {
    create_inspection_plan: string;
    create_service_record: string;
    fill_inspection_plan: string;
    request_service_history: string;
    send_documents: string;
  };
  timezone: string;
  vehicleTypesExist?: boolean;
  sendRequest: (data: any) => Promise<any>;
}) => {
  const {
    make,
    isLoading,
    state = SERVICE_STATE.OPEN,
    hasCollapsableContent = false,
    form = false,
    vin = '',
    service = {},
    workshop,
    statusCodes,
    addons = [],
    features,
    timezone,
    vehicleTypesExist = false,
    sendRequest,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [processStepsState, setProcessStepsState] = useState<SetStateAction<any>>(defaultStepState);
  const [isContentVisible, setIsContentVisible] = useBoolean(!hasCollapsableContent);
  const unlockFeatureRef = useRef(null);

  const blockingFeaturesServiceRecord = ['in_progress', 'on_hold', 'in_clarification', 'submitted', 'generated'];
  const blockingFeaturesServiceHistory = ['on_hold', 'in_progress'];
  // flag for disabling complete service button
  const isSubmitServiceDisabled =
    (!!features && blockingFeaturesServiceRecord.includes(features?.create_service_record)) ||
    (!!features && blockingFeaturesServiceHistory.includes(features?.request_service_history));

  const destroyInspectionPlanMutation = useDestroyInspectionPlanMutation();

  const isServiceNotCompleted = service?.completed_at === null;

  const inspectionPlanDestroyId = service?.service_activities?.find((activity: any) => activity?.type === 'inspection_plan')?.inspection_plan?.id;

  useEffect(() => {
    setProcessStepsState({ ...features });
  }, [features, service?.features]);

  const queryClient = useQueryClient();
  const uploadDocumentMutation = useUploadDocumentMutation();
  const sendDocumentsMutation = useSendDocumentsMutation(service?.id);
  // const storeServiceDocumentMutation = useStoreServiceDocumentMutation(service?.id);
  const completeServiceMutation = useCompleteServiceMutation(service?.id);
  const deleteDocumentMutation = useDeleteDocumentMutation();

  const accountsQuery = useAccountsQuery();
  const accountsData = accountsQuery.data?.data || [];
  const transformedAccounts = transformManufacturerAccounts(accountsData) as unknown as Array<TransformedAccounts>;

  const account = transformedAccounts.find((account) => {
    if (account?.make_name == make) {
      return account;
    } else {
      return;
    }
  });
  const accountStatusEqualsNotAvailable = account?.account.status === 50;
  const workshopHasVat = workshop?.vat_number;
  const currency = workshop?.currency;
  const identifiedVehicle = Boolean(vin);
  const requiresIdentification = isEmpty(service) && !identifiedVehicle;
  const activeAddons = get(workshop?.subscription, 'active_addons', {});
  const hasInspectionPlanAddOn = get(activeAddons, ADDON_KEYS.INSPECTION_PLANS_ADDON, false);
  const [searchParams] = useSearchParams();
  const createdServiceRecordId = searchParams.get('entryId');

  const serviceActivities = get(service, 'service_activities', []);

  const vehicleQuery = useVehicleQuery(vin);
  const vehicleData = vehicleQuery.data?.data;

  const isInitialRegistrationDateMissing = vehicleData?.initial_registration_date === null;

  const accountBlockedDeletedInProgress =
    vehicleData?.account?.status === 100 || vehicleData?.account?.status === 700 || vehicleData?.account?.status === 900;

  const accountRejected = vehicleData?.account?.status === 800;

  const isWorkshopPendingVerification = workshop.is_verification_pending;

  const documents = get(service, 'documents', []);
  const userContext = useUserContext();

  const supportPhoneNumber = userContext?.config?.support_telephone;
  const supportEmail = userContext?.config?.support_email;
  const isMazdaAndCountryDe = make === 'Mazda' && workshop?.country === 'DE';
  const modalData = GetAddonModalData({
    isMazdaAndCountryDe: isMazdaAndCountryDe,
    addon: find(addons, { id: ADDON_KEYS.PLUS_DSB }),
    activeAddons: activeAddons,
    activeAddon: get(workshop?.subscription?.active_addons, ADDON_KEYS.PLUS_DSB, {}),
    // @ts-ignore
    subscription: workshop?.subscription,
    costControlDisabled: workshop?.disable_cost_control,
    isTrial: true,
    displaySpecific: true,
    onClose: () => navigate('/app/w/vehicle?vin=' + vehicleData?.vin),
  });

  const isServiceRecordCreatedFromInspectionPlan = service?.service_activities?.some((service: any) => {
    return typeof service?.service_record?.from_inspection_plan_id === 'number';
  });

  const inspectionPlanExists = service?.service_activities?.some((service: any) => {
    return !!service?.inspection_plan;
  });

  const isNonLegacyInspectionPlanCreated = service?.service_activities?.some((service: any) => {
    return service?.inspection_plan?.status !== 999;
  });

  const stepsConfig = {
    service_history_request: {
      step: 1,
      isActive:
        processStepsState.request_service_history === 'available' ||
        processStepsState.request_service_history === 'not_available_account_not_registered' ||
        processStepsState.request_service_history === 'not_available_account_rejected',
      isCompleted: processStepsState.request_service_history === 'completed',
      isCanceled: processStepsState.request_service_history === 'canceled',
      isProcessing: processStepsState.request_service_history === SERVICE_STATE.PROCESSING,
      isDisabled:
        processStepsState.request_service_history === 'unavailable' ||
        processStepsState.request_service_history === 'not_available_no_dsb' ||
        processStepsState.request_service_history === 'not_available_manufacturer_not_supported' ||
        processStepsState.request_service_history === 'on_hold',
      isUnique: ['unavailable', SERVICE_STATE.PROCESSING].includes(processStepsState.request_service_history),
      requiresVehicleType: false,
      requiresIdentification,
    },
    inspection_plan: {
      step: 2,
      isActive:
        processStepsState.create_inspection_plan === 'available' ||
        processStepsState.create_inspection_plan === 'not_available_for_ppu' ||
        processStepsState.create_inspection_plan === 'not_available_no_addon',
      isCompleted: processStepsState.create_inspection_plan === 'completed',
      isDisabled:
        processStepsState.create_inspection_plan === 'not_available' ||
        processStepsState.create_inspection_plan === 'not_available' ||
        processStepsState.create_inspection_plan === 'not_available_no_dsb' ||
        processStepsState.create_inspection_plan === 'not_available_no_initial_registration_date' ||
        processStepsState.create_inspection_plan === 'not_available_no_vehicle_type',
      requiresVehicleType: true,
    },
    inspection_plan_fillout: {
      step: 3,
      isActive: processStepsState.fill_inspection_plan === 'available',
      isCompleted: processStepsState.fill_inspection_plan === 'completed',
      isProcessing: processStepsState.fill_inspection_plan === 'in_progress',
      isDisabled: processStepsState.fill_inspection_plan === 'not_available',
      requiresVehicleType: true,
      requiresIdentification,
    },
    service_record: {
      step: 4,
      isActive:
        processStepsState.create_service_record === 'available' ||
        processStepsState.create_service_record === 'not_available_account_not_registered' ||
        processStepsState.create_service_record === 'not_available_account_rejected' ||
        processStepsState.create_service_record === 'not_available_for_ppu' ||
        processStepsState.create_service_record === 'not_available_no_addon',
      isCompleted: processStepsState.create_service_record === 'completed',
      isCanceled: processStepsState.create_service_record === 'canceled',
      isProcessing:
        processStepsState.create_service_record === 'submitted' ||
        processStepsState.create_service_record === 'in_progress' ||
        processStepsState.create_service_record === 'generated',
      isInClarification: processStepsState.create_service_record === 'in_clarification',
      isRequestedDeletion: processStepsState.create_service_record === 'requested_deletion',
      isDisabled: processStepsState.create_service_record === 'not_available' || processStepsState.create_service_record === 'requested_deletion',
      isUnique: ['unavailable', SERVICE_STATE.PROCESSING, 'in_clarification'].includes(processStepsState.create_service_record),
      requiresVehicleType: false,
      requiresIdentification,
    },
    sent_documents: {
      step: 5,
      isActive: processStepsState.send_documents === 'available',
      isCompleted: processStepsState.send_documents === 'completed',
      isProcessing: processStepsState.send_documents === SERVICE_STATE.PROCESSING,
      isDisabled: processStepsState.send_documents === 'not_available',
      requiresVehicleType: false,
      requiresIdentification,
    },
  };

  const stepSubContentConfig = {
    service_history_request: {
      subContent: tool({
        icon: <ServiceHistoryRequestIcon stroke={getCardColor({ ...stepsConfig.service_history_request })} boxSize="10" alignSelf={'center'} />,
        color: getCardColor({ ...stepsConfig.service_history_request }),
        label: t('components:service_process_card.service_history_request.request_label'),
        isLoading,
        ...stepsConfig.service_history_request,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.service_history_request,
          ...stepsConfig.service_history_request,
          handler: async () => {
            const { isCompleted } = stepsConfig.service_history_request;
            if (!workshopHasVat && accountStatusEqualsNotAvailable) {
              return NiceModal.show(AlertModal, {
                content: {
                  icon: (
                    <Square size="10" borderRadius="md" display={'inline'}>
                      <Icon color={'orange'} as={IoIosWarning} boxSize="10" />
                    </Square>
                  ),
                  header: t('workshop:registration.without_vat.service_process_card.modal.no_oeplus_dsb_addon.title'),

                  footer: {
                    buttons: {
                      hide: true,
                    },
                  },
                },
                childrenTransI18n: (
                  <Trans
                    i18nKey={`workshop:registration.without_vat.service_process_card.modal.no_oeplus_dsb_addon.body`}
                    values={{
                      supportPhoneNumber: supportPhoneNumber,
                      supportEmail: supportEmail,
                    }}
                  />
                ),
              });
            }

            if (isCompleted) {
              if (hasCollapsableContent) {
                setIsContentVisible.on();
                await awaitAnimation();
              }
              // TODO: Get ID of Item somehow to get to proper selector as built in ServiceActivityTimeline
              //@ts-ignore
              document.querySelector(`service_record_${createdServiceRecordId}`)?.scrollIntoView({
                behavior: 'smooth',
              });
            } else {
              //if the workshop is NOT verified, show a modal which tells the user that he needs to verify his workshop first, before he can request service histories
              if (processStepsState.request_service_history === 'not_available_workshop_not_verified') {
                NiceModal.show(AlertModal, {
                  onSubmit: () => navigate('/app/w/account/verification'),
                  content: {
                    icon: (
                      <Square size="10" borderRadius="md" display={'inline'}>
                        <Icon color={'orange'} boxSize="8" />
                      </Square>
                    ),
                    header: isWorkshopPendingVerification
                      ? t('components:verification_pending.header')
                      : t('components:verification_not_started.header'),
                    footer: {
                      buttons: {
                        hide: isWorkshopPendingVerification,
                        actionCaption: t('components:verification_not_started.cta'),
                      },
                    },
                  },
                  children: isWorkshopPendingVerification ? t('components:verification_pending.text') : t('components:verification_not_started.text'),
                });
                //if the workshop is verified, the vehicle has a DSB, an account for the DSB account exists, but the portal can't be accessed yet, show a modal which tells the user that the account registration is still taking place
              } else if (processStepsState.request_service_history === 'not_available_account_not_registered') {
                NiceModal.show(AlertModal, {
                  accountRegistrationNotification: true,
                  accountRejected: accountRejected,
                  accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                  notifyWhenReady: vehicleData?.account?.notify_when_ready,
                  accountId: vehicleData?.account?.id,
                  onSubmit: () => navigate('/app/w/account-registration-status'),
                  content: {
                    icon: (
                      <Square size="10" borderRadius="md" display={'inline'}>
                        <Icon color={'orange'} boxSize="8" />
                      </Square>
                    ),
                    header: t('components:manufacturer_not_registered.header'),
                    footer: {
                      buttons: {
                        hide: true,
                        actionCaption: t('components:manufacturer_not_registered.cta'),
                      },
                    },
                  },
                  children: t('components:manufacturer_not_registered.text'),
                });
                //if the workshop is verified, and the vehicle has no DSB, or it has a DSB which either doesn't require an account or the account is already active, show the user the SHR form
              } else if (processStepsState.request_service_history === 'not_available_account_rejected') {
                NiceModal.show(AlertModal, {
                  accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                  accountId: vehicleData?.account?.id,
                  content: {
                    icon: <Icon as={IoIosWarning} color={'red'} boxSize="8" />,
                    header: t('components:manufacturer_account_rejected.title'),
                    footer: {
                      buttons: {
                        hide: true,
                      },
                    },
                  },
                  children: (
                    <Box mt={5} mb={5}>
                      <Trans
                        i18nKey="components:manufacturer_account_rejected.message"
                        values={{
                          phoneNumber: supportPhoneNumber,
                          email: supportEmail,
                        }}
                      />
                    </Box>
                  ),
                });
              } else {
                navigate(`/app/w/request-service-record-history?vin=${vin}`);
              }
            }
          },
          isDisabled: processStepsState.request_service_history === 'on_hold' || !!service?.completed_at,
          defaultMessage: ((): string => {
            if (state === SERVICE_STATE.COMPLETED) {
              return '';
            } else if (processStepsState.request_service_history === 'completed') {
              return t(`components:service_process_card.service_history_request.completed.tooltip`);
            } else if (processStepsState.request_service_history === 'not_available_manufacturer_not_supported') {
              return t(`components:service_process_card.service_history_request.not_supported.tooltip`);
            } else if (processStepsState.request_service_history === 'not_available_no_dsb') {
              return t(`components:service_process_card.service_history_request.no_dsb.tooltip`);
            } else if (processStepsState.request_service_history === 'in_progress') {
              return t(`components:service_process_card.service_history_request.processing.tooltip`);
            } else if (processStepsState.request_service_history === 'on_hold') {
              return t(`components:service_process_card.service_history_request.on_hold.tooltip`);
            } else if (processStepsState.request_service_history === 'available') {
              return t(`components:service_process_card.service_history_request.open.tooltip`);
            } else if (
              processStepsState.request_service_history === 'not_available_account_not_registered' ||
              processStepsState.request_service_history === 'not_available_account_rejected'
            ) {
              return t(`components:service_process_card.service_history_request.open.tooltip`);
            } else if (processStepsState.request_service_history === 'not_available_workshop_not_verified') {
              return t(`components:service_process_card.service_history_request.workshop_not_verified.tooltip`);
            } else {
              return '';
            }
          })(),
          noDsbAvailable: processStepsState.request_service_history === 'not_available_no_dsb',
          testId: 'service-history-button',
          tourId: 'service-history-request',
        }),
      }),
    },
    inspection_plan: {
      subContent: tool({
        icon:
          (!hasInspectionPlanAddOn && state !== SERVICE_STATE.OPEN && !stepsConfig.inspection_plan?.isDisabled) ||
          processStepsState.create_inspection_plan === 'not_available_for_ppu' ||
          processStepsState.create_inspection_plan === 'not_available_no_addon' ? (
            <UnlockFeature
              currency={currency}
              ref={unlockFeatureRef}
              workshop={workshop}
              addons={addons}
              addonId={ADDON_KEYS.INSPECTION_PLANS_ADDON}
              label={t(`components:service_process_card.inspection_plan.available.label`)}
              color={'#3f7dc1'}
              onSuccess={() => navigate(`/app/w/request-inspection-plan?vin=${vin}`)}
              icon={
                <Icon
                  as={BsCardChecklist}
                  boxSize="10"
                  fill={getCardColor({
                    ...stepsConfig.inspection_plan,
                    isDisabled: stepsConfig.inspection_plan?.isDisabled,
                  })}
                  alignSelf={'center'}
                />
              }
            />
          ) : (
            <Icon
              as={BsCardChecklist}
              boxSize="10"
              fill={getCardColor({
                ...stepsConfig.inspection_plan,
                isDisabled: stepsConfig.inspection_plan?.isDisabled,
              })}
              alignSelf={'center'}
            />
          ),
        color: getCardColor({
          ...stepsConfig.inspection_plan,
          isDisabled: stepsConfig.inspection_plan?.isDisabled,
        }),
        label:
          !hasInspectionPlanAddOn && state !== SERVICE_STATE.OPEN && !stepsConfig.inspection_plan?.isDisabled
            ? null
            : t(`components:service_process_card.inspection_plan.available.label`),
        isLoading,
        ...stepsConfig.inspection_plan,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.create_inspection_plan,
          ...stepsConfig.inspection_plan,
          handler: async () => {
            if (
              processStepsState.create_inspection_plan === 'not_available_for_ppu' ||
              processStepsState.create_inspection_plan === 'not_available_no_addon'
            ) {
              return;
            } else if (!isServiceRecordCreatedFromInspectionPlan && inspectionPlanExists) {
              NiceModal.show(AlertModal, {
                onSubmit: () => {
                  if (isNonLegacyInspectionPlanCreated) {
                    destroyInspectionPlanMutation.mutate(
                      {
                        vin: vin,
                        id: inspectionPlanDestroyId,
                      },
                      {
                        onSuccess: () => navigate(`/app/w/request-inspection-plan?vin=${vin}`),
                      },
                    );
                  } else {
                    return navigate(`/app/w/request-inspection-plan?vin=${vin}`);
                  }
                },
                content: {
                  header: t(`components:service_process_card.inspection_plan.available.label`),
                  footer: {
                    buttons: {
                      cancelCaption: t('common:cancel'),
                      actionCaption: t('common:continue'),
                    },
                  },
                },
                children: t('forms:ip.new_iP_warning'),
              });
            } else if (hasCollapsableContent || (stepsConfig.inspection_plan?.isCompleted && !SERVICE_STATE.COMPLETED)) {
              setIsContentVisible.on();
              await awaitAnimation();
            } else if (
              !(!hasInspectionPlanAddOn && state !== SERVICE_STATE.OPEN && !stepsConfig.inspection_plan?.isDisabled) ||
              !isServiceRecordCreatedFromInspectionPlan
            ) {
              navigate(`/app/w/request-inspection-plan?vin=${vin}`);
            }
          },
          isDisabled:
            isServiceRecordCreatedFromInspectionPlan ||
            isInitialRegistrationDateMissing ||
            stepsConfig.inspection_plan?.isDisabled ||
            !!service?.completed_at,
          defaultMessage: (() => {
            if (state === SERVICE_STATE.COMPLETED) {
              return '';
            } else if (processStepsState.create_inspection_plan === 'available') {
              return t(`components:service_process_card.inspection_plan.available.label`);
            } else if (processStepsState.create_inspection_plan === 'completed') {
              return t(`components:service_process_card.inspection_plan.completed.tooltip`);
            } else {
              return '';
            }
          })(),
        }),
        unlockFeatureRef,
        needsIdsbAddon: !hasInspectionPlanAddOn,
        testId: 'inspection-plan-button',
        tourId: 'inspection-plan',
      }),
    },
    inspection_plan_fillout: {
      subContent: tool({
        icon: (
          <Icon
            as={BsCardChecklist}
            boxSize="10"
            fill={getCardColor({
              ...stepsConfig.inspection_plan_fillout,
              isDisabled: stepsConfig.inspection_plan_fillout?.isDisabled,
            })}
            alignSelf={'center'}
          />
        ),
        color: getCardColor({
          ...stepsConfig.inspection_plan_fillout,
          isDisabled: stepsConfig.inspection_plan_fillout?.isDisabled,
        }),
        label: t('components:service_process_card.fill_inspection_plan.label'),
        isLoading,
        ...stepsConfig.inspection_plan_fillout,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.fill_inspection_plan,
          ...stepsConfig.inspection_plan_fillout,
          handler: async () => {
            if (
              stepsConfig.inspection_plan_fillout.isActive ||
              stepsConfig.inspection_plan_fillout.isProcessing ||
              stepsConfig.inspection_plan_fillout.isCompleted
            ) {
              return navigate(
                `/app/w/inspection-plan/${
                  service?.service_activities?.find((activity: any) => activity?.type === 'inspection_plan')?.inspection_plan?.id
                }?vin=${vin}`,
              );
            }
          },
          isDisabled:
            isServiceRecordCreatedFromInspectionPlan ||
            isInitialRegistrationDateMissing ||
            stepsConfig.inspection_plan_fillout?.isDisabled ||
            !!service?.completed_at,

          defaultMessage: (() => {
            if (state === SERVICE_STATE.COMPLETED) {
              return '';
            } else if (processStepsState.fill_inspection_plan === 'not_available') {
              return t('components:service_process_card.fill_inspection_plan.label');
            } else if (processStepsState.fill_inspection_plan === 'not_available_legacy') {
              return t('components:service_process_card.legacy.tooltip');
            } else if (processStepsState.fill_inspection_plan === 'completed') {
              return t('components:service_process_card.fill_inspection_plan.completed.tooltip');
            } else if (processStepsState.fill_inspection_plan === 'available') {
              return t('components:service_process_card.fill_inspection_plan.label');
            } else if (processStepsState.fill_inspection_plan === 'in_progress') {
              return t('components:service_process_card.fill_inspection_plan.label');
            } else {
              return '';
            }
          })(),
        }),
        unlockFeatureRef,
        noIpBlocksFillout:
          processStepsState.create_inspection_plan === 'not_available_for_ppu' || processStepsState.create_inspection_plan === 'available',
        testId: 'inspection-plan-button',
        tourId: 'inspection-plan',
      }),
    },
    service_record: {
      subContent: tool({
        icon:
          processStepsState.create_service_record === 'not_available_for_ppu' ? (
            <UnlockFeature
              currency={currency}
              ref={unlockFeatureRef}
              workshop={workshop}
              addons={addons}
              addonId={ADDON_KEYS.INSPECTION_PLANS_ADDON}
              label={t('components:service_process_card.service_record.create_label')}
              color={'#3f7dc1'}
              icon={
                <Icon
                  as={BsCardChecklist}
                  boxSize="10"
                  fill={getCardColor({
                    ...stepsConfig.inspection_plan,
                    isDisabled: stepsConfig.inspection_plan?.isDisabled,
                  })}
                  alignSelf={'center'}
                />
              }
            />
          ) : processStepsState.create_service_record === 'not_available_no_addon' ? (
            <UnlockFeature
              currency={currency}
              ref={unlockFeatureRef}
              workshop={workshop}
              addons={addons}
              addonId={ADDON_KEYS.PLUS_DSB}
              label={t('components:service_process_card.service_record.create_label')}
              color={'#3f7dc1'}
              icon={
                <Icon
                  as={BsCardChecklist}
                  boxSize="10"
                  fill={getCardColor({
                    ...stepsConfig.inspection_plan,
                    isDisabled: stepsConfig.inspection_plan?.isDisabled,
                  })}
                  alignSelf={'center'}
                />
              }
            />
          ) : (
            <ServiceRecordIcon fill={getCardColor(stepsConfig.service_record)} boxSize="10" alignSelf={'center'} />
          ),
        color: getCardColor(stepsConfig.service_record),
        label:
          processStepsState.create_service_record === 'not_available_for_ppu' || processStepsState.create_service_record === 'not_available_no_addon'
            ? undefined
            : t('components:service_process_card.service_record.create_label'),
        isLoading,
        ...stepsConfig.service_record,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.create_service_record,
          ...stepsConfig.service_record,
          handler: async () => {
            const { isCompleted } = stepsConfig.service_record;

            //NiceModals for Workshops Without VAT
            if (!workshopHasVat && accountStatusEqualsNotAvailable) {
              return NiceModal.show(AlertModal, {
                content: {
                  icon: (
                    <Square size="10" borderRadius="md" display={'inline'}>
                      <Icon color={'orange'} as={IoIosWarning} boxSize="10" />
                    </Square>
                  ),
                  header: t('workshop:registration.without_vat.service_process_card.modal.no_oeplus_dsb_addon.title'),
                  footer: {
                    buttons: {
                      hide: true,
                    },
                  },
                },
                childrenTransI18n: (
                  <Trans
                    i18nKey={`workshop:registration.without_vat.service_process_card.modal.no_oeplus_dsb_addon.body`}
                    values={{
                      supportPhoneNumber: supportPhoneNumber,
                      supportEmail: supportEmail,
                    }}
                  />
                ),
              });
            } else if (processStepsState.create_service_record === 'not_available_account_not_registered') {
              NiceModal.show(AlertModal, {
                accountRegistrationNotification: true,
                accountRejected: accountRejected,
                accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                notifyWhenReady: vehicleData?.account?.notify_when_ready,
                accountId: vehicleData?.account?.id,
                onSubmit: () => navigate('/app/w/account-registration-status'),
                content: {
                  icon: (
                    <Square size="10" borderRadius="md" display={'inline'}>
                      <Icon color={'orange'} boxSize="8" />
                    </Square>
                  ),
                  header: t('components:manufacturer_not_registered.header'),
                  footer: {
                    buttons: {
                      hide: true,
                      actionCaption: t('components:manufacturer_not_registered.cta'),
                    },
                  },
                },
                children: t('components:manufacturer_not_registered.text'),
              });
            } else if (processStepsState.create_service_record === 'not_available_account_rejected') {
              NiceModal.show(AlertModal, {
                accountBlockedDeletedInProgress: accountBlockedDeletedInProgress,
                accountId: vehicleData?.account?.id,
                content: {
                  icon: <Icon as={IoIosWarning} color={'red'} boxSize="8" />,
                  header: t('components:manufacturer_account_rejected.title'),
                  footer: {
                    buttons: {
                      hide: true,
                    },
                  },
                },
                children: (
                  <Box mt={5} mb={5}>
                    <Trans
                      i18nKey="components:manufacturer_account_rejected.message"
                      values={{
                        phoneNumber: supportPhoneNumber,
                        email: supportEmail,
                      }}
                    />
                  </Box>
                ),
              });
            } else if (
              processStepsState.create_service_record === 'not_available_for_ppu' ||
              processStepsState.create_service_record === 'not_available_no_addon'
            ) {
              NiceModal.show(modalData?.modalComponent, modalData);
              eventTrack('idsb_upsell');
              return null;
            } else if (isCompleted) {
              if (hasCollapsableContent) {
                setIsContentVisible.on();
                await awaitAnimation();
              }
              // TODO: Get ID of Item somehow to get to proper selector as built in ServiceActivityTimeline
              //@ts-ignore
              document.querySelector(`service_record_${createdServiceRecordId}`)?.scrollIntoView({
                behavior: 'smooth',
              });
            } else if (
              processStepsState.create_service_record === 'not_available_no_initial_registration_date' ||
              processStepsState.create_service_record === 'submitted'
            ) {
              return null;
            } else {
              //if the workshop is NOT verified, show a modal which tells the user that he needs to verify his workshop first, before he can create service records
              if (processStepsState.create_service_record === 'not_available_workshop_not_verified') {
                NiceModal.show(AlertModal, {
                  onSubmit: () => navigate('/app/w/account/verification '),
                  content: {
                    icon: (
                      <Square size="10" borderRadius="md" display={'inline'}>
                        <Icon color={'orange'} boxSize="8" />
                      </Square>
                    ),
                    header: isWorkshopPendingVerification
                      ? t('components:verification_pending.header')
                      : t('components:verification_not_started.header'),
                    footer: {
                      buttons: {
                        hide: isWorkshopPendingVerification,
                        actionCaption: t('components:verification_not_started.cta'),
                      },
                    },
                  },
                  children: isWorkshopPendingVerification ? t('components:verification_pending.text') : t('components:verification_not_started.text'),
                });
              }
              //if the workshop is verified, the vehicle has a DSB, an account for the DSB account exists, but the portal can't be accessed yet, show a modal which tells the user that the account registration is still taking place
              else {
                navigate(`/app/w/service-record/create?vin=${vin}`);
              }
            }
          },
          isDisabled: !!service?.completed_at,
          defaultMessage: ((): string => {
            if (state === SERVICE_STATE.COMPLETED) {
              return '';
            } else if (
              processStepsState.create_service_record === 'not_available_account_not_registered' ||
              processStepsState.create_service_record === 'not_available_account_rejected'
            ) {
              return t(`components:service_process_card.service_record.open.tooltip`);
            } else if (processStepsState.create_service_record === 'not_available_for_ppu') {
              return t(`components:service_process_card.service_record.not_available_for_ppu.tooltip`);
            } else if (processStepsState.create_service_record === 'available') {
              return t(`components:service_process_card.service_record.open.tooltip`);
            } else if (processStepsState.create_service_record === 'in_progress' || processStepsState.create_service_record === 'submitted') {
              return t(`components:service_process_card.service_record.processing.tooltip`);
            } else if (processStepsState.create_service_record === 'in_clarification') {
              return t(`components:service_process_card.service_record.clarification.tooltip`);
            } else if (processStepsState.create_service_record === 'completed') {
              return t(`components:service_process_card.service_record.completed.tooltip`);
            } else if (processStepsState.request_service_history === 'not_available_workshop_not_verified') {
              return t(`components:service_process_card.service_record.workshop_not_verified.tooltip`);
            } else if (processStepsState.create_service_record === 'not_available_no_initial_registration_date') {
              return t(`components:service_process_card.service_record.no_initial_registration_date.tooltip`);
            } else {
              return '';
            }
          })(),
        }),
        testId: 'service-record-button',
        tourId: 'service-record',
      }),
    },
    sent_documents: {
      subContent: tool({
        icon: <Icon as={RiMailSendLine} fill={getCardColor({ ...stepsConfig.sent_documents })} boxSize="10" alignSelf={'center'} />,
        color: getCardColor({ ...stepsConfig.sent_documents }),
        label: t('components:service_process_card.sent_documents.label'),
        ...stepsConfig.sent_documents,
        vehicleTypesExist,
        interact: getInteractionContentBasedOnState({
          state: processStepsState.sent_documents,
          ...stepsConfig.sent_documents,
          handler: async () => {
            setIsContentVisible.on();
            await awaitAnimation();
            //@ts-ignore
            document.getElementById(`documents-anchor-${service?.id}`)?.scrollIntoView({
              behavior: 'smooth',
            });
          },
          defaultMessage: (() => {
            if (processStepsState.send_documents === 'not_available') {
              return t(`components:service_process_card.sent_documents.disabled.tooltip`);
            } else if (processStepsState.send_documents === 'completed') {
              return t(`components:service_process_card.sent_documents.completed.tooltip`);
            } else if (processStepsState.send_documents === 'available') {
              return t(`components:service_process_card.sent_documents.open.tooltip`);
            } else {
              return '';
            }
          })(),
        }),
        isLoading,
        testId: 'send-documents-button',
      }),
    },
  };

  const isNoActiveService = vehicleData?.active_service === null;

  const stepContent = merge(stepsConfig, stepSubContentConfig);
  if (vehicleQuery.isLoading || accountsQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Card data-test-id="service-process-card" className={state != 'past' ? 'tour-process-card' : 'tour-completed-services'}>
      {isLoading ? (
        <CardHeaderSkeleton />
      ) : (
        <HStack justifyContent={'space-between'} mb={30}>
          <HStack spacing="4" py={4} px={4}>
            <Box width={'full'} ml={2}>
              <Heading size="xs">
                {hasCollapsableContent
                  ? t('components:service_process_card.completed.header', { date: dateTransformer(service?.completed_at, timezone) })
                  : isNoActiveService
                    ? t('components:service_process_card.start_new.header')
                    : t('components:service_process_card.active.header', {
                        date: service?.created_at ? dateTransformer(service?.created_at, timezone) : undefined,

                        start_date: service?.created_at ? dateTransformer(service?.created_at, timezone) : undefined,

                        end_date: service?.created_at ? dateTransformer(service?.completed_at, timezone) : undefined,
                      })}
              </Heading>

              <Text as="div" fontSize="sm">
                {!vin
                  ? t('components:service_process_card.new.description')
                  : hasCollapsableContent
                    ? t('components:service_process_card.completed.description', { date: dateTransformer(service?.completed_at, timezone) })
                    : isNoActiveService
                      ? t('components:service_process_card.start_new.description')
                      : t('components:service_process_card.active.description', {
                          date: service?.created_at ? dateTransformer(service?.completed_at, timezone) : undefined,
                        })}
              </Text>
            </Box>
          </HStack>

          {isServiceNotCompleted && (
            <Stack>
              <Tooltip
                placement={'left'}
                label={t('components:service_process_card.general_process_disabled')}
                shouldWrapChildren
                isDisabled={!isSubmitServiceDisabled}
              >
                <Button
                  disabled={isSubmitServiceDisabled}
                  mr={2}
                  variant={'variant'}
                  color={isSubmitServiceDisabled ? 'error' : 'blue.500'}
                  borderColor={isSubmitServiceDisabled ? 'error' : 'blue.500'}
                  border={'1px'}
                  _hover={isSubmitServiceDisabled ? { bgColor: 'error', color: 'white' } : { bgColor: 'blue.500', color: 'white' }}
                  data-test-id="complete-button"
                  className={'service-process-card-complete-service'}
                  onClick={() =>
                    NiceModal.show(AlertModal, {
                      onSubmit: () => completeServiceMutation.mutateAsync().then(() => queryClient.invalidateQueries(useVehicleQuery.getKey(vin))),
                      children: t('components:service_process_card.complete.text'),
                      content: {
                        header: t('components:service_process_card.complete.title'),
                        footer: {
                          buttons: {
                            cancelCaption: t('common:cancel'),
                            actionCaption: t('components:service_process_card.complete.button'),
                          },
                        },
                      },
                    })
                  }
                >
                  {t('components:service_process_card.complete.button')}
                </Button>
              </Tooltip>
            </Stack>
          )}

          {hasCollapsableContent ? (
            <Button
              variant="ghost"
              onClick={setIsContentVisible.toggle}
              rightIcon={isContentVisible ? <FiChevronUp /> : <FiChevronDown />}
              mr={'2 !important'}
            >
              {isContentVisible ? t('common:less_details') : t('common:more_details')}
            </Button>
          ) : null}
        </HStack>
      )}
      <Stack direction={['row']} id={'tools'}>
        {Object.values(stepContent).map(
          (
            step: {
              isActive: boolean;
              isCompleted: boolean;
              isProcessing?: boolean;
              isInClarification?: boolean;
              isCanceled?: boolean;
              isDisabled?: boolean;
              isRequestedDeletion?: boolean;
            },
            key: number,
          ) => (
            <ServiceStep
              key={key}
              {...step}
              color={getCardColor(step)}
              isFirstStep={key === 0}
              isLastStep={Object.keys(stepContent).length === key + 1}
            />
          ),
        )}
      </Stack>

      <Stack direction={['column']} p={6}>
        {isLoading ? (
          <Skeleton height="10rem" />
        ) : !isEmpty(service) ? (
          <Collapse in={isContentVisible} animateOpacity>
            <Stack spacing={4}>
              <ServiceActivityTimeline
                make={make as string}
                className={'tour-process-card-timeline'}
                isLoading={isLoading}
                addons={addons}
                vin={vin}
                //@ts-ignore
                statusCodes={statusCodes}
                isServiceCompleted={hasCollapsableContent}
                workshop={workshop}
                serviceActivities={serviceActivities}
                sendRequest={sendRequest}
                noActionsAllowed={state === SERVICE_STATE.COMPLETED}
                pl={20}
                t={t}
              />
              {[SERVICE_STATE.OPEN, SERVICE_STATE.PROCESSING].includes(state) ? null : <Divider />}
              {[SERVICE_STATE.OPEN, SERVICE_STATE.PROCESSING].includes(state) ? null : (
                <DocumentsSection
                  callback={() => queryClient.invalidateQueries(useVehicleQuery.getKey(vin))}
                  costControlDisabled={workshop?.disable_cost_control}
                  subscription={workshop?.subscription}
                  addons={addons}
                  anchorId={`documents-anchor-${service?.id}`}
                  url={'service-records'}
                  parentId={service?.id}
                  isLoadingParent={isLoading}
                  documents={documents}
                  timezone={timezone}
                  sendRequest={(formData) => {
                    formData.append('subject_type', 'service');
                    //@ts-ignore
                    formData.append('serviceRecordId', service?.id);

                    return uploadDocumentMutation.mutate(formData, {
                      onSuccess: () => {
                        return queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
                      },
                    });
                  }}
                  remove={(serviceId, documentId) =>
                    deleteDocumentMutation.mutate(
                      { serviceId, documentId },
                      {
                        onSuccess: () => {
                          return queryClient.invalidateQueries(useVehicleQuery.getKey(vin));
                        },
                      },
                    )
                  }
                  sendFiles={(data: Array<number>) =>
                    sendDocumentsMutation.mutate(data, {
                      onSuccess: () => queryClient.invalidateQueries(useVehicleQuery.getKey(vin)),
                    })
                  }
                />
              )}
            </Stack>
          </Collapse>
        ) : null}
      </Stack>
    </Card>
  );
};

export default ServiceProcessCard;
