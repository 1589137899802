import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { Skeleton } from 'components/Skeleton';
import ToggleTab from 'components/ToggleTab';
import cuid from 'cuid';
import { range } from 'lodash';
import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilters, useGlobalFilter, useTable } from 'react-table';

import { DefaultFilterForColumn, GlobalFilter } from './Filter';

export const DataTable = ({
  columns,
  data,
  isMoreThenOnePage,
  currentPage = 1,
  perPage,
  total,
  loading,
  setPage,
  setPerPage,
  globalFilter,
  globalFilterPlaceholder,
  setGlobalFilter,
  hasFilters,
  resetFilter,
  customGlobalFilter,
  ...otherProps
}: {
  columns: Array<any>;
  data: Array<any>;
  currentPage?: number;
  perPage?: number;
  total?: number;
  loading?: boolean;
  resetFilter?(): void;
  isMoreThenOnePage: boolean;
  // eslint-disable-next-line no-unused-vars
  setPage?: SetStateAction<any>;
  // eslint-disable-next-line no-unused-vars
  setPerPage?: SetStateAction<any>;
  globalFilter?: string;
  setGlobalFilter?: SetStateAction<any>;
  hasFilters: boolean;
  globalFilterPlaceholder?: string;
  customGlobalFilter?: any;
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      defaultColumn: { Filter: DefaultFilterForColumn },
    },
    useFilters,
    useGlobalFilter,
  );

  const { t } = useTranslation();
  const pageOptions = range(0, total, 1);
  const filtersInitiallyOpen = false;
  return (
    <Stack direction={'column'} flex={1}>
      <Grid
        templateColumns={{
          base: '1fr',
          xl: 'minmax(auto,15rem) minmax(auto,15rem)  1fr 2fr',
        }}
        templateAreas={{ base: '', xl: `"filters filters . global"` }}
        gap={2}
      >
        {hasFilters ? (
          <GridItem area={{ base: '', xl: 'global' }}>
            <GlobalFilter
              globalFilterPlaceholder={globalFilterPlaceholder}
              //preGlobalFilteredRows={data}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              key={'search-' + cuid()}
            />
          </GridItem>
        ) : null}

        {customGlobalFilter ? (
          <GridItem area={{ base: '', xl: 'filters' }}>
            <Grid templateColumns={{ base: '1fr', xl: '1fr 1fr' }} gap={2}>
              {customGlobalFilter}
            </Grid>
          </GridItem>
        ) : null}
      </Grid>

      {hasFilters ? (
        <Grid gap={4} paddingBlock={4} paddingInline={4}>
          <HStack justifyContent={'space-between'}>
            <ToggleTab
              testId="table-filter-toggle"
              isInitiallyOpen={filtersInitiallyOpen}
              openCaption={t('components:table.filters.open')}
              closeCaption={t('components:table.filters.close')}
            >
              <Grid
                templateColumns={{ base: '1fr', lg: 'repeat(5, 1fr)' }}
                columnGap={2}
                rowGap={4}
              >
                {headerGroups.map(
                  (
                    headerGroup: {
                      getHeaderGroupProps: () => any;
                      headers: any[];
                    },
                    headerKey: number,
                  ) => {
                    return (
                      <React.Fragment key={headerKey}>
                        {headerGroup.headers
                          .filter((column) => column?.shouldFilter)
                          .map((column, headerChildKey) => {
                            return (
                              <Box key={headerChildKey}>
                                {column.shouldFilter ? (
                                  <>{column.render('Filter')}</>
                                ) : null}
                              </Box>
                            );
                          })}
                      </React.Fragment>
                    );
                  },
                )}
              </Grid>
            </ToggleTab>
            <Button onClick={resetFilter}>{t('components:table.filters.reset')}</Button>
          </HStack>
        </Grid>
      ) : null}

      <Box maxWidth={'100%'} overflow={'auto'}>
        <Table {...getTableProps()} {...otherProps}>
          <Thead>
            {headerGroups.map(
              (
                headerGroup: { getHeaderGroupProps: () => any; headers: any[] },
                headerKey: number,
              ) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={headerKey}>
                  {headerGroup.headers.map((column, headerChildKey) => (
                    <Th
                      {...column.getHeaderProps()}
                      style={{ width: column.width }}
                      key={headerChildKey}
                    >
                      {column.render('Header')}
                    </Th>
                  ))}
                </Tr>
              ),
            )}
          </Thead>

          {/* Loading Skeleton */}
          {loading ? (
            <Tbody {...getTableBodyProps()}>
              {Array.from({ length: 10 }).map(() => {
                return headerGroups.map((headerGroup, skeletonHeaderKey) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={skeletonHeaderKey}>
                    {headerGroup.headers.map((column, skeletonColumnKey) => (
                      <Td {...column.getHeaderProps()} key={skeletonColumnKey}>
                        <Skeleton height="20px" />
                      </Td>
                    ))}
                  </Tr>
                ));
              })}
            </Tbody>
          ) : null}

          {!loading &&
            (data.length == 0 ? (
              <Tbody data-test-id="no-matches">
                <Tr>
                  <Td colSpan={100}>{t('components:table.no_matches')}</Td>
                </Tr>
              </Tbody>
            ) : (
              <Tbody {...getTableBodyProps()}>
                {rows.map((row, i: any) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={i}>
                      {row.cells.map((cell, cellKey) => {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={cellKey}
                            backgroundColor={
                              cell.row.original.highlighted ? 'orange.100' : undefined
                            }
                          >
                            {cell.render('Cell')}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            ))}
        </Table>
      </Box>

      {isMoreThenOnePage && !loading ? (
        <Flex justifyContent="space-between" m={4} alignItems="center">
          <Flex>
            <Tooltip label={t('components:table.first')}>
              <IconButton
                onClick={() => setPage(1)}
                isDisabled={currentPage === 0}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
                data-test-id="table-first"
                aria-label={'first'}
              />
            </Tooltip>
            <Tooltip label={t('components:table.previous')}>
              <IconButton
                onClick={() => setPage(currentPage)}
                isDisabled={currentPage === 0}
                icon={<ChevronLeftIcon h={6} w={6} />}
                data-test-id="table-previous"
                aria-label={'previous'}
              />
            </Tooltip>
          </Flex>

          <Flex alignItems="center">
            <Text mr={8}>
              {t('components:table.page')}
              <Text fontWeight="bold" as="span">
                {currentPage + 1}
              </Text>{' '}
              {t('components:table.of')}
              <Text fontWeight="bold" as="span">
                {pageOptions.length}
              </Text>
            </Text>
            <Text> {t('components:table.go_to_page')}</Text>{' '}
            <NumberInput
              ml={2}
              mr={8}
              w={28}
              min={1}
              max={pageOptions.length}
              data-test-id="table-go-to"
              onChange={(value) => {
                const page = value ? parseInt(value) - 1 : 0;
                setPage(page + 1);
              }}
              defaultValue={currentPage + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              w={32}
              value={perPage}
              data-test-id="table-show-items"
              onChange={(e) => {
                setPerPage(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {t('components:table.show')} {pageSize}
                </option>
              ))}
            </Select>
          </Flex>

          <Flex>
            <Tooltip label={t('components:table.next')}>
              <IconButton
                onClick={() => setPage(currentPage + 2)}
                isDisabled={currentPage + 1 === total}
                icon={<ChevronRightIcon h={6} w={6} />}
                data-test-id="table-next"
                aria-label={'next'}
              />
            </Tooltip>
            <Tooltip label={t('components:table.last')}>
              <IconButton
                onClick={() => setPage(total)}
                isDisabled={currentPage + 1 === total}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
                data-test-id="table-last"
                aria-label={'last'}
              />
            </Tooltip>
          </Flex>
        </Flex>
      ) : null}
    </Stack>
  );
};
