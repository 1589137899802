import React, { useState } from 'react';
import { default as LibraryModalVideo, ModalVideoProps } from 'react-modal-video';

import VideoPlaceholder from './VideoPlaceholder';

export interface IModalVideoProps {
  placeholder?: string;
  fallback?: string;
  videoId: string;
  autoplay?: boolean;
  channel?: ModalVideoProps['channel'];
}
export const ModalVideo: React.FC<IModalVideoProps> = (props) => {
  const {
    placeholder,
    videoId,
    autoplay = true,
    fallback = placeholder,
    channel = 'vimeo',
  } = props;

  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <LibraryModalVideo
        channel={channel}
        isOpen={isOpen}
        // @ts-ignore
        autoplay={autoplay}
        videoId={videoId}
        vimeo={{ title: false, portrait: false, byline: false }}
        onClose={() => setOpen(false)}
      />

      <VideoPlaceholder
        image={placeholder}
        fallback={fallback}
        onClick={() => setOpen(true)}
      />
    </>
  );
};

export default ModalVideo;
