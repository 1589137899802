import { useToast } from '@chakra-ui/react';
import { ArrayResults } from 'api/types';
import { AxiosInstance } from 'axios';
import config from 'config';
import { useAxios } from 'context/AxiosContextProvider';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

export interface Period {
  id: number;
  name: string;
  combinable: boolean;
  applicable: boolean;
  suggested: boolean;
  km: number;
  months: number;
}

export interface IMaintenanceSystem {
  id: number;
  name: string;
  fixed_interval: true;
  periods: Array<Period>;
}

async function getMaintenanceSystem(axios: AxiosInstance, vin: string): Promise<ArrayResults<IMaintenanceSystem>> {
  const { data } = await axios.get(`${config.apiBaseUrl}/workshop/vehicles/${vin}/inspection-plans/maintenance-systems`);
  return data;
}

export default function useMaintenanceSystemQuery(vin: string) {
  const axios = useAxios();
  const toast = useToast();
  const { t } = useTranslation();

  return useQuery(getKey(vin), () => getMaintenanceSystem(axios, vin), {
    enabled: !!vin,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      manageErrorResponse({ toastInstance: toast, error: JSON.stringify(error), t });
    },
  });
}

function getKey(vin: string): QueryKey {
  return ['MaintenanceSystem', vin];
}

useMaintenanceSystemQuery.getKey = getKey;
