import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { ArrayResults, VehicleModel } from '../../../api/types';
import config from '../../../config';
import { DURATION } from '../../../constants';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

async function getModels(
  axios: AxiosInstance,
  vin: string,
): Promise<ArrayResults<VehicleModel>> {
  const { data } = await axios.get(
    `${config.apiBaseUrl}/workshop/vehicles/${vin}/models`,
  );
  return data;
}

export default function useVehicleModelsQuery(vin: string) {
  const { t } = useTranslation();
  const toast = useToast();
  const axios = useAxios();
  return useQuery(getKey(vin), () => getModels(axios, vin), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 6,
    enabled: !!vin,
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}

function getKey(vin: string): QueryKey {
  return ['VehicleModels', vin];
}

useVehicleModelsQuery.getKey = getKey;
