import { useAuth0 } from '@auth0/auth0-react';
import { Center, Spinner } from '@chakra-ui/react';
import React, { useState } from 'react';

import config from '../config';
import ErrorPage from '../layout/errors/ErrorPage';

export const LoadingSpinner = () => {
  const [reachedTimeout, setReachedTimeout] = useState(false);
  const { logout, isLoading, isAuthenticated, error, user } = useAuth0();

  setTimeout(() => {
    setReachedTimeout(true);
  }, 15000);

  if (reachedTimeout) {
    return (
      <ErrorPage
        error={'timeout while authenticating user'}
        errorContext={{
          extra: {
            isLoading,
            isAuthenticated,
            error,
            user,
          },
        }}
        logoutAction={() => {
          logout({ returnTo: config.baseUrl, client_id: config.authClientId });
        }}
      />
    );
  }

  return (
    <Center>
      <Spinner
        size="xl"
        m={200}
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="accent"
      />
    </Center>
  );
};

export default LoadingSpinner;
