import { useUserContext } from 'context/UserContextProvider';
import { useIsWorkshopBlocked } from 'helpers/general';
import PageMetatags from 'layout/PageMetatags';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { LoadingSpinner } from '../../components/LoadingSpinner';
import { PromotionBanner } from '../../components/PromotionBanner';
import useServiceRecordByStatusQuery from '../../hooks/queries/service/useServiceRecordByStatusQuery';
import usePromotionsQuery from '../../hooks/queries/usePromotionsQuery';
import IdentifiedVehiclesCard from './IdentifiedVehiclesCard';
import SrInClarificationCard from './SrInClarificationCard';
import VehicleIdentificationFormCard from './VehicleIdentificationFormCard';
import WelcomeCard from './WelcomeCard';

const Dashboard = () => {
  const promotionsQuery = usePromotionsQuery();
  const promotionsData = promotionsQuery.data?.data || [];
  const { t } = useTranslation();
  const serviceRecordByStatusQuery = useServiceRecordByStatusQuery({
    status: 500,
    sortBy: 'submitted_at',
    sortOrder: 'asc',
    itemsPerPage: 10,
    page: 1,
  });

  const serviceRecordByStatusData = serviceRecordByStatusQuery.data?.data;
  const userContext = useUserContext();

  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (promotionsQuery.isLoading || serviceRecordByStatusQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageMetatags title={t('workshop:dashboard.meta.title')} />

      {promotionsData.map((promotion, index) => {
        if (promotion?.location?.includes('dashboard')) {
          return <PromotionBanner key={index} promotion={promotion} url={'/app/w/account/subscription/plan'} />;
        }
        return null;
      })}

      {userContext.user?.show_welcome_message && <WelcomeCard />}

      <VehicleIdentificationFormCard />

      {serviceRecordByStatusData && serviceRecordByStatusData?.length > 0 && <SrInClarificationCard />}

      <IdentifiedVehiclesCard />
    </>
  );
};

export default Dashboard;
