import { Box, Center, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import PageMetatags from 'layout/PageMetatags';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import GetInTouchCard from '../../components/GetInTouchCard';
import { Card } from '../../layout/Card';
import { ContentCard } from '../../layout/ContentCard';

export interface AccountUpdateDataPage {}

export const FallbackErrorPage: React.FC<AccountUpdateDataPage> = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageMetatags title={`${t('workshop:update.label')}`}></PageMetatags>
      <>
        <ContentCard>
          <Card p={{ base: 2, lg: 10 }} mb={4} textAlign="left">
            <Box height={'full'}>
              <Stack spacing={3}>
                <Heading as="h3" size="md" p={2} textAlign="center">
                  {t('errors:general')}
                </Heading>
                <Center>
                  <Text color="muted" fontSize="sm" p={2} whiteSpace={'pre-wrap'} mt={10}>
                    <Text size={'sm'} textAlign="center">
                      <Trans i18nKey="errors:boundary.description" />
                    </Text>

                    <Center mb={8}>
                      <Image
                        src="/images/accident.svg"
                        alt="Error Gif"
                        w={'100%'}
                        h={'auto'}
                      />
                    </Center>

                    <Text size={'sm'} textAlign="center">
                      <Trans i18nKey="errors:500.message" />
                    </Text>
                  </Text>
                </Center>
                <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}></Flex>
              </Stack>
            </Box>
          </Card>

          <GetInTouchCard />
        </ContentCard>
      </>
    </>
  );
};

export default FallbackErrorPage;
