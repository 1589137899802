import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useTranslation } from 'react-i18next';
import { QueryKey, useQuery } from 'react-query';

import { Accounts, ArrayResults } from '../../../api/types';
import config from '../../../config';
import { DURATION } from '../../../constants';
import { useAxios } from '../../../context/AxiosContextProvider';
import { manageErrorResponse } from '../../../helpers/manageErrorResponse';

interface VehicleModelTypes {
  engine_code: string | null;
  fullname: string | null;
  hp_id: number | null;
  name: string | null;
  output: string | null;
}

async function getTypes(
  axios: AxiosInstance,
  vin: string,
  hp_id: number | null | undefined,
): Promise<ArrayResults<VehicleModelTypes>> {
  const { data } = await axios.get(
    `${config.apiBaseUrl}/workshop/vehicles/${vin}/models/${hp_id}/types`,
  );
  return data;
}

export default function useVehicleModelTypesQuery(
  vin: string,
  hp_id: number | null | undefined,
) {
  const { t } = useTranslation();
  const toast = useToast();
  const axios = useAxios();
  return useQuery(getKey(vin, hp_id), () => getTypes(axios, vin, hp_id), {
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    enabled: !!vin && !!hp_id,
    retry: 6,
    refetchOnWindowFocus: false,
    cacheTime: DURATION.Hour,
    staleTime: DURATION.Minute * 5,
    onError: (error: any) => {
      manageErrorResponse({
        toastInstance: toast,
        error: JSON.stringify(error),
        t,
      });
    },
  });
}

function getKey(vin: string, hp_id: number | null | undefined): QueryKey {
  return ['VehicleModelTypes', vin, hp_id];
}

useVehicleModelTypesQuery.getKey = getKey;
