import * as Sentry from '@sentry/react';
import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import config from '../../../config';

async function getFile(url: string | undefined) {
  const { data } = await axios.get(config.spfApiBaseUrl + url, {
    responseType: 'blob',
  });
  return data;
}

export default function useSPFFileQuery(url: string | undefined) {
  const navigate = useNavigate();
  return useQuery(getKey(url), () => getFile(url), {
    enabled: !!url,
    notifyOnChangeProps: ['data', 'isFetching', 'error'],
    retry: 2,
    refetchOnWindowFocus: false,
    onError: (error) => {
      Sentry.captureException(error, {
        extra: { url },
      });
      navigate('/complete-registration/error', { replace: true });
    },
  });
}

function getKey(url: string | undefined): QueryKey {
  return ['File', url];
}

useSPFFileQuery.getKey = getKey;
