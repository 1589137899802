import {
  Button,
  Center,
  Grid,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import { CaptureContext } from '@sentry/types';
import { isNil } from 'lodash';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Card } from '../Card';
import { PublicPageLayout } from '../PublicPageLayout';

export interface IErrorPageProps {
  error?: string;
  errorContext?: CaptureContext;
  logoutAction?: () => void;
}

export const ErrorPage: React.FC<IErrorPageProps> = (props) => {
  const navigate = useNavigate();
  const {
    error = 'Error',
    errorContext = {},
    logoutAction = () => navigate('/app/logout'),
  } = props;

  const { t } = useTranslation();

  if (!isNil(error) && error?.length > 0) {
    captureException(error, errorContext);
  }

  const resetError = useCallback(() => {
    navigate('/');
  }, [error]);
  return (
    <PublicPageLayout>
      <Grid w="full" h="full" placeItems="center" data-test-id="error-boundary">
        <Card p={{ base: 2, lg: 20 }} textAlign="center">
          <Stack spacing={8}>
            <Heading as="h2" size="md" p={2} textAlign="center">
              {t('errors:boundary.title')}
            </Heading>
            <Text size={'sm'}>
              <Trans i18nKey="errors:boundary.description" />
            </Text>
            <Center mb={8}>
              <Image src="/images/accident.svg" alt="Error Gif" w={'50%'} h={'auto'} />
            </Center>

            <Text size={'sm'}>
              <Trans i18nKey="errors:boundary.cta" />
            </Text>

            <HStack alignSelf={'center'}>
              <Button variant={'primary'} onClick={resetError}>
                {t('errors:boundary.back')}
              </Button>
              <Button variant={'outline'} as={'a'} onClick={logoutAction}>
                {t('errors:boundary.logout')}
              </Button>
            </HStack>
          </Stack>
        </Card>
      </Grid>
    </PublicPageLayout>
  );
};

export default ErrorPage;
