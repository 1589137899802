import config from '../config';

/**
 * Helpers for Buildings and Environment Checks
 */

// eslint-disable-next-line
export enum Environments {
  INT = 'int',
  QA = 'qa',
  CYPRESS = 'cypress',
  LOCAL = 'local',
  STAGE = 'stage',
  PROD = 'prod',
}

export const isTestingEnvironment = () =>
  [
    Environments.INT,
    'integration',
    Environments.QA,
    Environments.CYPRESS,
    Environments.LOCAL,
    Environments.STAGE,
  ].includes(config.appEnv);

export const isProdEnvironment = () => config.appEnv === Environments.PROD;
