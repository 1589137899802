import { Button, Flex, Icon, Stack } from '@chakra-ui/react';
import Link from 'layout/Link';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsExclamationCircleFill } from 'react-icons/bs';

import { ServiceRecordIcon } from '../../components/Icons';
import { DataTable } from '../../components/Table/DataTable';
import { dateTimeTransformer } from '../../helpers/dateTransformer';
import useServiceRecordByStatusQuery from '../../hooks/queries/service/useServiceRecordByStatusQuery';
import { ContentCard } from '../../layout/ContentCard';

const SrInClarificationCard = () => {
  const { t } = useTranslation();
  const [tablePage, setTablePage] = useState(1);
  const [itemsPerTablePage, setItemsPerTablePage] = useState(10);

  useEffect(() => {
    setTablePage(1);
  }, [itemsPerTablePage]);

  const serviceRecordByStatusQuery = useServiceRecordByStatusQuery({
    status: 500,
    sortBy: 'submitted_at',
    sortOrder: 'asc',
    itemsPerPage: itemsPerTablePage,
    page: tablePage,
  });
  const serviceRecordByStatusData = serviceRecordByStatusQuery.data?.data;

  const currentPage = serviceRecordByStatusQuery?.data?.meta?.current_page ? serviceRecordByStatusQuery?.data?.meta?.current_page - 1 : 1;

  const columns = React.useMemo(
    () => [
      {
        accessor: 'highlighted',
        // @ts-ignore
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return row?.original?.highlighted ? <Icon as={BsExclamationCircleFill} boxSize={6} mr={-50} color="orange.400" /> : null;
        },
        width: '10px',
      },
      {
        Header: t('forms:id.label'),
        accessor: 'user_readable_id',
      },
      {
        Header: t('forms:vin.label'),
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return <>{row?.original?.vehicle?.vin ?? '-'}</>;
        },
      },
      {
        Header: t('forms:make.label'),
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return <>{row?.original?.vehicle?.make ?? '-'}</>;
        },
      },
      {
        Header: t('forms:vehicle_type_id.label'),
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return <>{row?.original?.vehicle?.vehicle_type?.fullname ?? '-'}</>;
        },
      },
      {
        Header: t('forms:date.label'),
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return <>{dateTimeTransformer(row?.original?.date) ?? '-'}</>;
        },
      },
      {
        id: 'button',
        Cell: (cellProps: any) => {
          const { row } = cellProps;
          return (
            <Flex>
              <Button
                ml={'auto'}
                as={Link}
                to={`/app/w/service-record/${row?.original?.id}?vin=${row?.original?.vehicle?.vin}`}
                variant="primary"
                size="sm"
                data-test-id={'service-record-' + row?.original?.id}
                _hover={{ textDecoration: 'none' }}
              >
                {t('common:details')}
              </Button>
            </Flex>
          );
        },
      },
    ],
    [serviceRecordByStatusData?.data],
  );

  return (
    <ContentCard
      icon={<ServiceRecordIcon fill={'orange.500'} boxSize="10" alignSelf={'center'} />}
      minH="fit-content"
      header={t('service_record:status.clarification.sr_in_clarification.title')}
      contentDescription={t('service_record:status.clarification.sr_in_clarification.description')}
      data-test-id="sr-in-clarification-card"
    >
      <Stack direction={['column', 'row']} w={'100%'}>
        <Stack direction="column" w={'100%'}>
          <DataTable
            data-test-id="sr-in-clarification-table"
            hasFilters={false}
            columns={columns}
            loading={serviceRecordByStatusQuery.isLoading}
            data={serviceRecordByStatusData || []}
            // @ts-ignore
            total={serviceRecordByStatusQuery?.data?.meta?.last_page || 1}
            currentPage={currentPage}
            setPage={setTablePage}
            perPage={itemsPerTablePage}
            setPerPage={setItemsPerTablePage}
            isMoreThenOnePage={true}
          />
        </Stack>
      </Stack>
    </ContentCard>
  );
};

export default SrInClarificationCard;
