import { Button, Flex, Text } from '@chakra-ui/react';
import { useIsWorkshopBlocked } from 'helpers/general';
import useCreateServiceHistoryRequestMutation from 'hooks/private/mutations/useCreateServiceHistoryRequestMutation';
import PageMetatags from 'layout/PageMetatags';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { ServiceHistoryRequestIcon } from '../../components/Icons';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { useUserContext } from '../../context/UserContextProvider';
import useServiceHistoryQuery from '../../hooks/queries/service/useServiceHistoryQuery';
import useVehicleQuery from '../../hooks/queries/useVehicleQuery';
import { ContentCard } from '../../layout/ContentCard';
import NeedsVerificationModal from '../serviceRecord/NeedsVerificationModal';

const ServiceHistoryPage = () => {
  const userContext = useUserContext();
  const { id } = useParams();
  const serviceHistoryId = Number(id);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const urlParams = new URLSearchParams(window.location.search);
  const vin = urlParams.get('vin') || '';
  const createServiceHistoryMutation = useCreateServiceHistoryRequestMutation(vin);

  const requestServiceHistory = () => {
    const historyBackTimes = 50;
    if (!createServiceHistoryMutation.isLoading) {
      createServiceHistoryMutation.mutate(serviceHistoryRequestData);
      queryClient.invalidateQueries(useVehicleQuery.getKey(vin));

      for (let i = 0; i < historyBackTimes; i++) {
        history.pushState({}, '', '/app/w/vehicle?vin=' + vehicleQuery.data?.data?.vin);
      }

      return navigate('/app/w/vehicle?vin=' + vehicleQuery.data?.data?.vin, {
        replace: true,
      });
    }
  };
  useEffect(() => {
    let validUrl = false;
    if ((isNil(id) || id === 'null') && (isNil(vin) || vin === '')) {
      throw new Error(t('errors:invalid_url'));
    }
    if (id && (isNil(vin) || vin === '')) {
      validUrl = true;
    }
    if ((isNil(id) || id === 'null') && vin) {
      validUrl = true;
    }
    if (!validUrl) {
      throw new Error(t('errors:invalid_url'));
    }
  }, [id, vin]);

  const serviceHistoryQuery = useServiceHistoryQuery(serviceHistoryId);
  const serviceHistoryData = serviceHistoryQuery.data?.data;

  const vehicleQuery = useVehicleQuery(vin);
  let vehicleData = vehicleQuery.data?.data;

  if (!vin) {
    vehicleData = serviceHistoryData?.vehicle;
  }

  const serviceHistoryRequestData = {
    vehicle_id: vehicleData?.id,
  };

  const isWorkshopBlocked = useIsWorkshopBlocked();
  if (isWorkshopBlocked) {
    return <Navigate to="/app/w/account/subscription/cost-overview" replace={true} />;
  }
  if (vehicleQuery.isLoading || serviceHistoryQuery.isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <PageMetatags title={`${t('pages:service_history_request.create.label')} ${id ? `ID: ${id}` : `VIN: ${vin}`}`} />
      <NeedsVerificationModal vehicle={vehicleData} workshop={userContext.workshop} />
      <ContentCard
        isLoading={isEmpty(vehicleData)}
        icon={<ServiceHistoryRequestIcon boxSize="10" m={1} />}
        header={t('pages:service_history_request.create.label')}
        data-test-id="service-history"
      >
        <Text color="muted" fontSize="sm" textAlign="left">
          {t('pages:service_history_request.create.description')}
        </Text>
        <Flex direction="row-reverse" pt="16" pb="5" px={{ base: '4', md: '6' }}>
          <Button
            ml={3}
            type="submit"
            onClick={requestServiceHistory}
            variant="primary"
            data-test-id="service-history-submit-button"
            disabled={createServiceHistoryMutation.isLoading}
          >
            {t('pages:service_history_request.create.label')}
          </Button>
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            mr={1}
            data-test-id="service-history-review-back-button"
            flexShrink={0}
            color="error"
            borderColor="error"
            _hover={{ bgColor: 'error', color: 'white' }}
          >
            {t('common:abort')}
          </Button>
        </Flex>
      </ContentCard>
    </>
  );
};

export default ServiceHistoryPage;
